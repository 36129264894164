import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Box, Text, VStack } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/react";
import { useState } from "react";
import { useMutation } from "react-query";
import { createContact } from "../../api/contact";
import { MaskedInput } from "../atoms/MaskedInput";

export function ReceiveCallForm({ onClose, doneStyle = {} }) {
  const [done, setDone] = useState(false);
  const [cellphone, setCellphone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const { isLoading, mutate } = useMutation(
    "contact",
    (data) => createContact(data),
    {
      onSuccess: () => {
        setDone(true);
      },
    }
  );

  const _onClose = () => {
    setDone(false);
    onClose();
  };

  const onSubmit = (e) => {
    e.preventDefault()
    mutate({
      name,
      email,
      cellphone
    });
  };

  if (done)
    return (
      <VStack
        marginTop="10px"
        marginBottom="20px"
        alignItems="flex-start"
        w="100%"
      >
        <Text fontWeight="medium" {...doneStyle} pb="20px">
          Obrigado pelo seu interesse. Agora falta pouco! Logo um de nossos
          consultores entrará em contato.
        </Text>
        {onClose ? (
          <Button
            alignSelf="center"
            margin="auto"
            bgColor="#D91F59"
            color="white"
            fontWeight="bold"
            mb="20px"
            onClick={_onClose}
          >
            Concluir
          </Button>
        ) : (
          <></>
        )}
      </VStack>
    );

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing={2}>
        <HStack>
          <Input
            backgroundColor="white"
            type="name"
            placeholder="Insira seu nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Input
            backgroundColor="white"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Insira seu email. Ex: joao@gmail.com"
            required
          />
        </HStack>
        <MaskedInput
          placeholder="Insira seu numero de celular. Ex: (61) 9 9981-7892"
          mask="(99) 9 9999-9999"
          value={cellphone}
          onChange={(e) => setCellphone(e.target.value)}
          required
        />
        <Box pt="20px" mb="20px" justifyContent="right">
          <Button
            bgColor="#D91F59"
            color="white"
            fontWeight="bold"
            mb="20px"
            isLoading={isLoading}
            type="submit"
          >
            Receba uma ligação
          </Button>
        </Box>
      </VStack>
    </form>
  );
}
