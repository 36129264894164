import {
  Stack,
  Box,
  Image,
  ButtonProps,
  Heading,
  Text,
  List,
  ListIcon,
  ListItem,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { MainButton } from "../../components/atoms/MainButtons";
import { Footer } from "../../components/organisms/Footer";
import { NavBar } from "../../components/organisms/Header";
import { ImageSection } from "../../components/organisms/ImageSection";
import { InYourHands } from "../../components/organisms/InYourHands";
import { Morethan } from "../../components/organisms/Morethan";
import { TestimonyCarousel } from "../../components/organisms/TestimonyCarousel";
import { Fade } from "react-reveal";
import { FixedButtons } from "../../components/molecules/FixedButtons";
import { MobileDrawer } from "../../components/organisms/MobileDrawer";
import { openPopupWidget } from "react-calendly";
import { useCheckMobile } from "../../utils/CheckMobile";
import { ReceiveCall } from "../../components/molecules/ReceiveCall";

const listText = [
  {
    text: "Campanhas semanais personalizadas",
  },
  {
    text: "Divulgação de novas parcerias",
  },
  {
    text: "Incentivo ao uso da plataforma",
  },
  {
    text: "Comunicação através de push notifications",
  },
  {
    text: "E muito mais...",
  },
];

const franchiseList = [
  {
    text: "Lorem ipsum sit dolor amet.",
  },
  {
    text: "Consectetur adispicing elit.",
  },
  {
    text: "Pellentesque in mollis augue.",
  },
];

const FranchisesMainBox = ({ openReceiveCallModal, bookCalendlyMeet }) => {
  return (
    <Stack
      direction={{ base: "column-reverse", lg: "row" }}
      w="100%"
      p="5%"
      bgGradient="linear(to-t, #D82B60, #FFA800)"
      justify="space-evenly"
    >
      <Stack
        direction="column"
        alignContent="center"
        justifyContent="center"
        spacing={8}
        maxW="600px"
      >
        <Heading
          fontFamily="Chivo"
          fontSize="45px"
          color="white"
          fontWeight="bold"
        >
          Leve a Partiu! para sua cidade
        </Heading>
        <Text fontWeight="Chivo" fontSize="25px" color="white">
          Que tal oferecer as empresas um clube de vantagens completo na sua
          cidade?
        </Text>
        <Stack direction="row" align="center">
          <MainButton
            onClick={openReceiveCallModal}
            isWhite={true}
            text="Receba uma ligacao agora"
          />
          <Text fontSize="20px" color="white" fontFamily="Chivo">
            OU{" "}
          </Text>
          <MainButton
            onClick={bookCalendlyMeet}
            isWhite={true}
            text="Agende uma reuniao"
          />
        </Stack>
      </Stack>
      <Image
        w="auto"
        h={{ base: "auto", lg: "630px" }}
        alt="Imagem de como o seu clube ficara"
        src="https://partiu-static.s3.amazonaws.com/images/DesenhoComprar.png"
      />
    </Stack>
  );
};

const JoinOurTeam = () => {
  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      w="100%"
      alignContent="center"
      justifyContent="space-around"
      p="5%"
      spacing={4}
    >
      <Image
        src="https://partiu-static.s3.amazonaws.com/images/puzzle.png"
        w={{ base: "auto", lg: "auto" }}
        maxH={{ base: "auto", lg: "400px" }}
        alt="Juntos cresceremos!"
      />
      <Stack
        direction="column"
        justifyContent="center"
        alignContent="center"
        w={{ base: "auto", lg: "500px" }}
        pl="30px"
      >
        <Heading fontFamily="Chivo" fontWeight="bold" color="#DF2E54">
          Junte-se ao time
        </Heading>
        <Text fontFamily="Chivo" fontSize="17px" color="gray">
          Há 6 anos somos responsáveis por criar e gerenciar o clube de
          vantagens de mais de 60 empresas em diversos segmentos.
        </Text>
        <Text fontSize="17px" fontFamily="Chivo" color="gray">
          Nosso time é composto por 40 colaboradores, com DNA empreendedor,
          comprometido e focado nas novidades do mercado. Mais que um clube de
          vantagens, somos uma ferramenta de conhecimento do consumidor,
          engajamento e retenção para empresas.
        </Text>
        <Text fontSize="17px" fontFamily="Chivo" color="gray">
          Em 3 minutos criamos e subimos um site whitelabel e em seguida
          integramos ao site/app do cliente.
          <br></br>
          <strong>Veja o que oferecemos: </strong>
        </Text>
        <List alignSelf="center" fontSize="17px" color="gray">
          <ListItem>
            <ListIcon as={MdCheckCircle} color="#DF2E54" />
            Site e apliacativo com descontos <b>reais</b>.
          </ListItem>
          <ListItem>
            <ListIcon as={MdCheckCircle} color="#DF2E54" />
            Personalizado com a identidade visual da empresa.
          </ListItem>
          <ListItem>
            <ListIcon as={MdCheckCircle} color="#DF2E54" />
            Para uso de funcionários e seus dependentes, assim como para seus
            clientes.
          </ListItem>
          <ListItem>
            <ListIcon as={MdCheckCircle} color="#DF2E54" />
            Tecnologia e campanhas <b>100%</b> white label.
          </ListItem>
          <ListItem>
            <ListIcon as={MdCheckCircle} color="#DF2E54" />
            Parcerias fisicas nos estados em que atuamos.
          </ListItem>
          <ListItem>
            <ListIcon as={MdCheckCircle} color="#DF2E54" />
            Curadoria de parcerias.
          </ListItem>
        </List>
      </Stack>
    </Stack>
  );
};

export default function Franchises() {
  const receiveCallDisclosure = useDisclosure();
  const isMobile = useCheckMobile();

  const url = "https://calendly.com/prevendas-bju/reuniao-partiu";

  const pageSettings = {
    backgroundColor: "cbe5e2",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
  };

  const onClick = () => openPopupWidget({ url, pageSettings });

  return (
    <>
      <NavBar />
      <FranchisesMainBox
        openReceiveCallModal={receiveCallDisclosure.onOpen}
        bookCalendlyMeet={onClick}
      />
      <ImageSection
        text="Já estamos presente em mais de 8 estados em todo o pais.  Que tal oferecer um Clube de Vantagens completo na sua cidade? Seja um franqueado Partiu você também!"
        isReversed={false}
        hasDivisor={true}
        openReceiveCallModal={receiveCallDisclosure.onOpen}
        bookCalendlyMeet={onClick}
        src={"https://partiu-static.s3.amazonaws.com/images/mapaBrasil.png"}
        alt="Mapa do Brasil com as cidades que estamos presentes"
        title="Queremos expandir para sua cidade!"
      />
      <Morethan />
      <JoinOurTeam />
      <ImageSection
        isReversed={true}
        openReceiveCallModal={receiveCallDisclosure.onOpen}
        bookCalendlyMeet={onClick}
        hasList={true}
        whichList={listText}
        hasDivisor={true}
        hasForm={false}
        src={
          "https://partiu-static.s3.amazonaws.com/images/ImagemVerticais.png"
        }
        title="Os clientes conectados a grandes marcas!"
        alt="Exemplos de descontos que a Partiu oferece."
        text="Através do clube de vantagens com a identidade visual da empresa, os clientes estarão conectados a milhares de estabelecimentos físicos e ecommerces com descontos exclusivos."
      />
      <ImageSection
        isReversed={false}
        hasDivisor={true}
        openReceiveCallModal={receiveCallDisclosure.onOpen}
        bookCalendlyMeet={onClick}
        title="Rentabilidade e crescimento"
        text="Nosso foco é proporcionar aos nossos clientes um relacionamento duradouro com o público deles, agregar valor a marca e levar benefícios ao consumidor final.Queremos ser uma empresa de referência na criação de clube de vantagens e estamos trabalhando para isso.
        Faça parte de um mercado que fatura milhões por ano. Seja um franqueado Partiu!"
        src="https://partiu-static.s3.amazonaws.com/images/rocket.png"
      />

      <TestimonyCarousel
        items={[
          {
            text: "A Partiu está conosco há 4 anos e sempre tivemos um atendimento muito presente, buscando agregar valor para nossos colaboradores e clientes tanto na administradora quanto na imobiliária.",
            author: "Marcelo Borges",
            job: "Diretor Administrativo ML Administradora",
            image: "https://partiu-static.s3.amazonaws.com/faces/marceloml.png",
            logo: "https://partiu-static.s3.amazonaws.com/Logos/ml-administradora.png",
            company: "ML Administradora",
          },
          {
            text: "Iniciamos a parceria em 2017. Ao longo dos anos percebemos sempre a constante evolução no produto, trazendo novas parcerias relevantes para síndicos e condôminos.",
            author: "Dennys Teles",
            job: "Diretor administrativo Atlântida",
            image:
              "https://partiu-static.s3.amazonaws.com/faces/dennysatlantida.png",
            logo: "https://partiu-static.s3.amazonaws.com/Logos/atlantida.png",
            company: "Atlantida Administradora",
          },
          {
            text: "A experiência com a Partiu Vantagens tem sido transformadora em nosso empreendimento, tando para o nosso negócio, quanto para as pessoas.",
            author: "Danilo",
            job: "Diretor - UBANNET",
            image:
              "https://cdn.discordapp.com/attachments/1042581944879284317/1101599735942492182/366c32d6-e102-4f98-83fc-03f175eececf.png",
            logo: "https://partiu-rest-s3.s3.sa-east-1.amazonaws.com/logos/Logo-Uba-clube-de-vantagens.png",
            company: "UBANNET",
          },
          {
            text: "Ter a Partiu como parceiro proporcionou muita muitas vantagens para os nossos clientes.",
            author: "Mauro",
            job: "Diretor - MMA FIBRA",
            image:
              "https://cdn.discordapp.com/attachments/1042581944879284317/1101600049533816965/96fce3e0-3139-4954-9692-9c1d4627b16f.png",
            logo: "https://partiu-rest-s3.s3.sa-east-1.amazonaws.com/logos/logommafibra.png",
            company: "MMA FIBRA",
          },
          {
            text: "A Partiu é uma grande parceira. Esta relação de sucessos foi construída pela qualidade das suas soluções tecnológicas e principalmente, pelo foco e empatia de toda a equipe.",
            author: "Jardel",
            job: "Diretor Presidente - Assemed",
            image:
              "https://partiu-static.s3.amazonaws.com/faces/jardelassemed.jpeg",
            logo: "https://partiu-static.s3.amazonaws.com/Logos/assemed.png",
          },
        ]}
      />
      <ReceiveCall {...receiveCallDisclosure} />
      <FixedButtons
        openReceiveCallModal={receiveCallDisclosure.onOpen}
        bookCalendlyMeet={onClick}
      />
      {isMobile ? (
        <MobileDrawer openReceiveCallModal={receiveCallDisclosure.onOpen} />
      ) : (
        <></>
      )}
      <Footer />
    </>
  );
}
