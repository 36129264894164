import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import BePartner from "./pages/bepartner";
import Demonstracao from "./pages/demonstracao";
import Home from "./pages/Home";
import WhoWeAre from "./pages/quem-somos";
import Franchises from "./pages/franchises";
import Provedores from "./pages/verticais/provedores";
import ForYou from "./pages/for-you";
import Revista from "./pages/revista";
import Plans from "./pages/plans";

export const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/seja-parceiro">
          <BePartner />
        </Route>
        <Route path="/seja-franqueado">
          <Franchises />
        </Route>
        <Route path="/quem-somos">
          <WhoWeAre />
        </Route>
        <Route path="/demonstracao">
          <Demonstracao />
        </Route>
        <Route path="/verticais/provedores">
          <Provedores />
        </Route>
        <Route path="/paravoce">
          <ForYou />
        </Route>
        <Route path="/revista">
          <Revista />
        </Route>
        <Route path="/planos">
          <Plans />
        </Route>
      </Switch>
    </Router>
  );
};
