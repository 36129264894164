import React from "react";
import { Button, Image } from "@chakra-ui/react";

export const WhatsappButton = () => {
  return (
    <a
      style={{ zIndex: 100000 }}
      target="_blank"
      rel="noopener noreferrer"
      href="https://api.whatsapp.com/send?phone=5521970021967&text=Olá, gostaria de falar com a Partiu!"
    >
      <Button
        colorScheme="transparent"
        borderRadius="100px"
        height="120px"
        width="120px"
        justifyContent="center"
        alignContent="center"
        m="0vh -5vh 0vh -3vh"
      >
        <Image
          src="https://partiu-static.s3.amazonaws.com/icons/WhatsApp.png"
          alt="Ícone do Whatsapp"
        />
      </Button>
    </a>
  );
};
