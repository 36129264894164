import React, { Component, useRef } from "react";
import "./ImageSelector.scss";
import { Heading, VStack } from "@chakra-ui/layout";

function ImageSelector({ name, image, onChange, label, error }) {
  const fileInput = useRef();

  function triggerInput(e) {
    fileInput.current.click();
  }

  function handleImage() {
    if (fileInput.current) {
      console.log("rendering");
      let reader = new FileReader();

      reader.onloadend = (e) => {
        onChange({
          name: name,
          value: reader.result,
        });
      };

      reader.readAsDataURL(fileInput.current.files[0]);
    }
  }

  function renderBackground() {
    if (image) {
      return { backgroundImage: `url('${image}'` };
    }

    return {};
  }

  return (
    <VStack alignItems="flex-start" flex="1">
      <Heading
        textAlign="left"
        fontFamily="Montserrat"
        marginBottom="10px"
        fontSize="14px"
      >
        {label.toUpperCase()}
      </Heading>
      <div
        onClick={triggerInput}
        className="image-selector"
        style={error ? { borderColor: "red" } : {}}
      >
        <input
          onChange={handleImage}
          ref={fileInput}
          className="file-input"
          type="file"
        />
        <div className="preview" style={renderBackground()}></div>
        <div style={{ backgroundColor: "orange" }} className="select">
          <p>Selecionar</p>
        </div>
      </div>
    </VStack>
  );
}

export default ImageSelector;
