import {
  Flex,
  Drawer,
  Button,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FaChevronUp } from "react-icons/fa";
import {
  openPopupWidget,
  PopupWidget,
  InlineWidget,
  PopupButton,
} from "react-calendly";
import { Calendly } from "../molecules/Calendly";
import { MainButton } from "../atoms/MainButtons";
import { openReceiveCallModal } from "../molecules/ReceiveCall";

function CalendlyOpen() {
  return (
    <PopupWidget
      color="white"
      text="Agende uma reunião"
      textColor="#fffff"
      url="https://calendly.com/prevendas-bju/reuniao-partiu"
    />
  );
}
export const MobileDrawer = ({ openReceiveCallModal }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const preFill = {
    name: "Jon Snow",
    firstName: "Jon",
    lastName: "Snow",
    email: "test@test.com",
  };

  const url = "https://calendly.com/prevendas-bju/reuniao-partiu";

  const _repeatShake = useState(0);
  const disclosure = useDisclosure();

  useEffect(() => {
    const interval = setInterval(() => {
      _repeatShake[1](_repeatShake[0] + 1);
    }, 4000);

    return () => clearInterval(interval);
  }, [_repeatShake]);

  const pageSettings = {
    backgroundColor: "cbe5e2",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
  };

  const onClick = () => openPopupWidget({ url, preFill, pageSettings });

  return (
    <Flex justify="center">
      <Button
        bgColor="#fc195a"
        bottom="5px"
        position="fixed"
        zIndex="2"
        p="3.5vh 5vh 3.5vh 5vh"
        fontSize="18px"
        fontWeight="bold"
        w="80%"
        color="white"
        ref={btnRef}
        onClick={onOpen}
        align="center"
        boxShadow="0px 0px 10px rgba(0,0,0,0.7)"
        key={_repeatShake[0]}
        border="1px solid"
        borderColor="white"
        leftIcon={<FaChevronUp />}
      >
        Receba uma ligacão agora
      </Button>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom"
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <VStack spacing={4} justify="center" align="center">
              <MainButton
                text="Agende uma reunião"
                url={url}
                prefill={preFill}
                pageSettings={pageSettings}
                onClick={onClick}
              ></MainButton>

              <MainButton
                text="Receba uma ligação agora"
                isWhite={false}
                onClick={openReceiveCallModal}
              />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
