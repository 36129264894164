import {
  Avatar,
  Center,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import ReactPlayer from "react-player";
import { Divisor } from "../atoms/Divider";

function CarouselButton({ icon, onClick, ...style }) {
  const size = "30px";

  return (
    <Center
      boxShadow="0px 0px 10px rgba(0,0,0,0.4)"
      borderRadius="100px"
      width={size}
      height={size}
      backgroundColor="white"
      cursor="pointer"
      color="white"
      fontSize="20px"
      onClick={onClick}
      {...style}
    >
      {icon}
    </Center>
  );
}

const CarouselTitle = () => {
  return (
    <Flex
      justify={{ base: "center", lg: "flex-start" }}
      w="100%"
      pl={{ base: "5%", lg: "8%" }}
      direction="column"
    >
      <Divisor />
      <Heading
        justifySelf={{ base: "center", lg: "flex-start" }}
        fontWeight="bold"
        color="#DA2257"
      >
        Depoimentos
      </Heading>
      <Text fontSize="20px" fontWeight="medium">
        Saiba o que nossos clientes falam da Partiu!
      </Text>
    </Flex>
  );
};

const TestimonyVideo = () => {
  return (
    <Flex justify="center" mb="50px">
      <ReactPlayer
        height="400px"
        width="600px"
        url="https://www.youtube.com/watch?v=-Tttt7US2Sc&t=5s"
      />
    </Flex>
  );
};

function TestimonyCarouselCard({ text, image, author, job, logo, company }) {
  return (
    <VStack
      color="white"
      padding="40px"
      minWidth="400px"
      maxWidth="600px"
      backgroundImage="linear-gradient(45deg, #D82B60, #FFA800);"
      borderRadius="10px"
      alignItems="flex-start"
      spacing={7}
    >
      <HStack alignItems="flex-start">
        <Image
          h="40px"
          w="auto"
          src={logo}
          alt="Company that employs who did the testimony"
        />
      </HStack>
      <Text height="100px">{text}</Text>
      <HStack alignItems="flex-start">
        <Avatar src={image} alt="Image from testimony" />
        <VStack alignItems="flex-start" spacing={0}>
          <Text fontWeight="bold" fontSize="14px">
            {author}
          </Text>
          <Text fontSize="14px">{job}</Text>
        </VStack>
      </HStack>
    </VStack>
  );
}

export function TestimonyCarousel({ items }) {
  const carouselRef = useRef();

  useEffect(() => {
    if (!carouselRef.current) return;

    function checkVisible(elm) {
      var rect = elm.getBoundingClientRect();
      var viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }

    const interval = setInterval(() => {
      if (checkVisible(carouselRef.current))
        carouselRef.current.scrollLeft += 1;

      if (
        carouselRef.current.scrollLeft ===
        carouselRef.current.scrollWidth - carouselRef.current.offsetWidth
      ) {
        carouselRef.current.scrollLeft = 0;
      }
    }, 40);

    return () => clearInterval(interval);
  }, [carouselRef]);

  return (
    <VStack spacing={5} marginTop="10px" marginBottom="30px">
      <CarouselTitle />

      <HStack
        paddingBottom="30px"
        spacing={10}
        width="100%"
        paddingLeft={{ base: "5%", lg: "6%" }}
        overflowX="hidden"
        ref={carouselRef}
        scrollBehavior="smooth"
      >
        {items.map((item, index) => (
          <TestimonyCarouselCard {...item} key={index} />
        ))}
      </HStack>
      <HStack spacing={3}>
        <CarouselButton
          onClick={() => (carouselRef.current.scrollLeft -= 500)}
          color="rgba(0,0,0,0.3)"
          icon={<MdChevronLeft />}
        />
        <CarouselButton
          onClick={() => (carouselRef.current.scrollLeft += 500)}
          icon={<MdChevronRight />}
          backgroundImage="linear-gradient(45deg, #D82B60, #FFA800);"
        />
      </HStack>
    </VStack>
  );
}
