import React, { useEffect, useState } from "react";
import {
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
  Box,
  Text,
} from "@chakra-ui/react";
import Fade from "react-reveal/Fade";

export const InYourHands = () => {
  return (
    <Flex
      direction={{ base: "column-reverse", lg: "row" }}
      w="100%"
      transform="translateX(7%)"
      flexWrap="nowrap"
      mb={{ base: "5%", lg: "50px" }}
      mt="50px"
      zIndex="2px"
      position="relative"
      justify="space-between"
    >
      <Fade bottom>
        <Stack
          direction="column"
          alignSelf="center"
          textAlign={{ base: "center", lg: "left" }}
          justifySelf="flex-start"
          spacing={2}
          maxW="400px"
        >
          <Heading
            color="#fc195a"
            fontFamily="Chivo, sans-serif"
            fontWeight="bold"
            fontSize="20pt"
            pt="5vh"
            textAlign="left"
          >
            Tenha um clube de vantagens integrado e com a identidade visual de
            sua empresa
          </Heading>
          <Text
            color="gray.600"
            fontSize="24pt"
            fontFamily="Chivo, sans-serif"
            textAlign="center"
          >
            <strong>em apenas</strong>
          </Text>
          <Text
            color="red.600"
            fontSize="200px"
            fontFamily="Chivo, sans-serif"
            lineHeight="170px"
            fontWeight="extrabold"
            textAlign="center"
            borderRadius="40px"
            padding="0"
            className="animate__animated animate__pulse animate__infinite"
          >
            3
          </Text>
          <Text
            color="gray.600"
            fontSize="28pt"
            fontFamily="Chivo, sans-serif"
            textAlign="center"
          >
            <strong>dias.</strong>
          </Text>
        </Stack>
      </Fade>
      <Image
        src="https://partiu-static.s3.amazonaws.com/images/Smartfit.png"
        w="auto"
        alt="Imagem do nosso clube integrado e personlizado com a SmartFit"
        h={{ base: "auto", lg: "500px" }}
        objectFit="contain"
        justifySelf={{ base: "center", lg: null }}
        pb={{ base: "20px", lg: null }}
      />
    </Flex>
  );
};
