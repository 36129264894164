import { Heading, Stack, Text } from "@chakra-ui/react";
import ReactPlayer from "react-player";
import { Divisor } from "../atoms/Divider";

export const PromoVideo = () => {
  return (
    <Stack
      direction="column"
      w="100%"
      justify="center"
      align="center"
      spacing={0}
      pb="20px"
    >
      <Divisor />
      <Heading color="#DA2257" fontFamily="Chivo" pb="25px">
        Saiba mais sobre a Partiu!
      </Heading>

      <ReactPlayer
        height="400px"
        width="70%"
        url="https://www.youtube.com/watch?v=mJDwRfLJT7A"
      />
    </Stack>
  );
};
