import React, { useState } from "react";
import { Button } from "@chakra-ui/react";

export const MainButton = (props) => {
  const isWhite = props.isWhite;

  return (
    <div>
      <Button
        color={isWhite ? "#fc195a" : "white"}
        bgColor={isWhite ? "white" : "#fc195a"}
        h="60px"
        p="10px"
        w={props.width ? props.width : { base: "140px", lg: "150px" }}
        whiteSpace="normal"
        text-align="center"
        fontWeight="semibold"
        display="flex"
        flexWrap="wrap"
        lineHeight="20px"
        onClick={props.onClick}
        fontSize={{ base: "15px", lg: "17px" }}
        borderRadius="lg"
        _hover={!props.disabled ? {
          boxShadow: "0 10px 10px rgb(0 0 0 / 20%)",
          transform: "translateY(-5px)",
        } : {}}
        {...props}
      >
        {props.text}
      </Button>
    </div>
  );
};
