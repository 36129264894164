import React, { useEffect, useMemo, useState } from "react";

import { Footer } from "../../components/organisms/Footer";
import { HomeMainBox } from "../../components/organisms/HomeMainbox";
import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FormInput } from "../../components/atoms/FormInput";
import { LoadingScreen } from "../../components/molecules/LoadingScreen";
import { ShadowBox } from "../../components/molecules/ShadowBox";
import { FaCheck } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { getPlans } from "../../api/plans";
import { MainButton } from "../../components/atoms/MainButtons";
import { useForm } from "react-hook-form";
import jwt from "jsonwebtoken";
import { axiosInstanceV2 } from "../../api/axios";

function PlanDetail({ children }: { children: any }) {
  return (
    <HStack spacing={5}>
      <FaCheck size="20pt" color="green" />
      <Text width="220px" fontSize="11pt" lineHeight="1.35rem">
        {children}
      </Text>
    </HStack>
  );
}

function Plan({
  active,
  name,
  onClick,
}: {
  active?: boolean;
  name: string;
  onClick: Function;
}) {
  return (
    <Heading
      cursor="pointer"
      padding="10px"
      textAlign="center"
      onClick={onClick}
      borderRadius="10px"
      fontSize="12pt"
      width="100%"
      {...(active
        ? {
            background:
              "linear-gradient(90deg, rgb(216, 43, 96), rgb(255, 168, 0))",
            color: "white",
          }
        : {})}
    >
      {name}
    </Heading>
  );
}

function PlanSelector({
  plans,
  selectedPlan,
  setSelectedPlan,
}: {
  plans: object[];
  selectedPlan: number;
  setSelectedPlan: Function;
}) {
  return (
    <HStack
      marginBottom="30px"
      borderRadius="10px"
      justify="space-evenly"
      width="100%"
      border="1px solid #dedede"
    >
      {plans.map((p, index) => (
        <Plan
          onClick={() => setSelectedPlan(index)}
          name={p.name.replace("Plano ", "")}
          active={index == selectedPlan}
        />
      ))}
    </HStack>
  );
}

export default function Plans() {
  const toast = useToast();
  const { isLoading, data = [] } = useQuery("plans", getPlans);
  const { register, handleSubmit, getValues } = useForm();
  const createAgreementMutation = useMutation(
    () =>
      axiosInstanceV2.post("/agreements/", {
        ...getValues(),
        physical_card: false,
      }),
    {
      onSuccess: () => {
        const plan = data[selectedPlan];
        const values = getValues();
        const token = jwt.sign(
          {
            customer_email: values.owner_email,
            customer_name: values.owner_name,
            customer_code: values.cnpj,
            customer_registry_code: values.cnpj,
          },
          plan.vindi_token
        );

        window.open(plan.vindi_page + "?payload=" + token, "_blank");
      },
      onError: (error) => {
        console.log(error);
        toast({
          title: "Oops!",
          status: "error",
          description:
            "Parece que já existe um usuário criado com este email ou CPF.",
        });
      },
    }
  );
  const [selectedPlan, setSelectedPlan] = useState(0);

  return (
    <LoadingScreen isLoading={isLoading}>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <HomeMainBox hideForm={true} />

        <Box
          backgroundColor="white"
          position="relative"
          width="102%"
          height="300px"
          marginBottom="-540px"
          transform={{
            base: "translateY(-20%)",
            lg: "rotate(3deg) translateY(-60%) translateX(-2%);",
          }}
          zIndex="0px"
        >
          <Box
            width={{ base: "50px", lg: "300px" }}
            height={{ base: "100px", lg: "400px" }}
            backgroundColor="transparent"
            boxShadow={{ base: "0px 50px white", lg: "0px 200px white" }}
            borderBottomLeftRadius="200px"
            position="absolute"
            top={{ base: "-100px", lg: "-400px" }}
          />
          <Box
            width={{ base: "50px", lg: "400px" }}
            height={{ base: "100px", lg: "500px" }}
            backgroundColor="transparent"
            boxShadow={{ base: "0px 50px white", lg: "0px 300px white" }}
            borderBottomRightRadius="200px"
            position="absolute"
            right="0px"
            top={{ base: "-100px", lg: "-500px" }}
          />
        </Box>

        <Stack
          zIndex={10}
          spacing={10}
          width="80%"
          margin="auto"
          position="relative"
          direction={{ base: "column", lg: "row" }}
        >
          <ShadowBox
            padding="30px 20px"
            maxWidth={{ base: "100%", lg: "320px" }}
          >
            <PlanSelector
              plans={data}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />
            <HStack width="100%" justify="center" textAlign="center">
              <Heading paddingTop="10px" alignSelf="flex-start" fontSize="14pt">
                R$
              </Heading>
              <Heading fontSize="40pt">
                {!isLoading ? data[selectedPlan].value.split(".")[0] : null}
              </Heading>
              <Heading alignSelf="flex-end" fontSize="8pt">
                Mensais
              </Heading>
            </HStack>
            <Divider margin="30px 0px" />
            <VStack spacing={4}>
              <PlanDetail>
                <b>{!isLoading ? data[selectedPlan].access_amount : null}</b>{" "}
                acessos para seu Clube de Vantagens.
              </PlanDetail>
              <PlanDetail>
                Mais de 1000 empresas, 2000 lojas e 5000 descontos.
              </PlanDetail>
              <PlanDetail>Utilização ilimitada para seus membros.</PlanDetail>
            </VStack>
          </ShadowBox>
          <ShadowBox padding="30px 30px" width="100%">
            <Heading fontSize="20pt">Crie seu Clube de Vantagens</Heading>
            <form onSubmit={handleSubmit(createAgreementMutation.mutate)}>
              <Stack
                width="100%"
                paddingTop="30px"
                direction={{ base: "column", lg: "row" }}
              >
                <FormInput
                  containerProps={{ width: "100%" }}
                  label="Nome Completo *"
                  required={true}
                  hookForm={register("owner_name")}
                />
                <FormInput
                  hookForm={register("owner_email")}
                  required={true}
                  containerProps={{ width: "100%" }}
                  label="E-mail *"
                />
              </Stack>
              <Stack
                paddingTop="20px"
                width="100%"
                direction={{ base: "column", lg: "row" }}
              >
                <FormInput
                  containerProps={{ width: "100%" }}
                  hookForm={register("name")}
                  required={true}
                  label="Nome da Empresa *"
                />
                <FormInput
                  hookForm={register("cnpj")}
                  containerProps={{ width: "100%" }}
                  required={true}
                  label="CPF *"
                  mask="999.999.999-99"
                />
              </Stack>
              <Stack
                paddingTop="20px"
                width="100%"
                direction={{ base: "column", lg: "row" }}
              >
                <FormInput
                  containerProps={{ width: "100%" }}
                  hookForm={register("owner_telephone")}
                  required={true}
                  label="Telefone *"
                />
                <FormInput
                  containerProps={{ width: "100%" }}
                  label="E-mail (Equipe de Marketing)"
                  hookForm={register("marketing_email")}
                />
              </Stack>
              <Stack
                paddingTop="20px"
                width="100%"
                direction={{ base: "column", lg: "row" }}
              >
                <FormInput
                  containerProps={{ width: "100%" }}
                  label="Plano"
                  disabled={true}
                  value={!isLoading ? data[selectedPlan].name : ""}
                />
                <Flex width="100%" justify="flex-end">
                  <MainButton
                    isLoading={isLoading || createAgreementMutation.isLoading}
                    alignSelf="flex-end"
                    height="40px"
                    marginTop="30px"
                    marginLeft="auto"
                    type="submit"
                    text="Pagamento >>"
                  />
                </Flex>
              </Stack>
            </form>
          </ShadowBox>
        </Stack>

        <Footer />
      </div>
    </LoadingScreen>
  );
}
