import {
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  HStack,
  Center,
  Box,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

const CarouselData = [
  {
    id: 2,
    area: "Shopping Centers",
    link: "https://www.google.com",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/shoppings.png",
  },
  {
    id: 3,
    area: "Provedores de internet",
    link: "https://www.iguatemi.com.br",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/provedores.png",
  },
  {
    id: 4,
    area: "Associações",
    link: "https://www.planob.online",
    imageSrc:
      "https://partiu-static.s3.amazonaws.com/areas/AreaAssociations.png",
  },
  {
    id: 5,
    area: "Sindicatos",
    link: "https://www.iguatemi.com.br",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/sindicatos.png",
  },
  {
    id: 6,
    area: "Administradora de Imóveis e Imobiliárias",
    link: "https://www.planob.online",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/AreaImobAdmin.png",
  },
  {
    id: 7,
    area: "Cursos de Inglês",
    link: "https://www.google.com",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/ingles.png",
  },
  {
    id: 8,
    area: "Recursos Humanos",
    link: "https://www.iguatemi.com.br",
    imageSrc:
      "https://partiu-static.s3.amazonaws.com/areas/recursoshumanos.png",
  },
  {
    id: 9,
    area: "Corretoras de seguros",
    link: "https://www.planob.online",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/seguradoras.png",
  },
  {
    id: 10,
    area: "Faculdades",
    link: "https://www.iguatemi.com.br",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/faculdades.png",
  },
];

const CarouselData_ = [
  {
    id: 1,
    area: "Lojas de Departamento",
    link: "https://www.meutimao.com.br",
    imageSrc:
      "https://partiu-static.s3.amazonaws.com/areas/lojasdepartamento.png",
  },
  {
    id: 2,
    area: "Bares",
    link: "https://www.google.com",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/baress.png",
  },
  {
    id: 3,
    area: "Farmácias",
    link: "https://www.iguatemi.com.br",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/farmácias.png",
  },
  {
    id: 4,
    area: "Lanchonetes",
    link: "https://www.planob.online",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/lanchonetes.png",
  },
  {
    id: 5,
    area: "Barbearias",
    link: "https://www.iguatemi.com.br",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/barbearias.png",
  },
  {
    id: 6,
    area: "Pet Shops",
    link: "https://www.planob.online",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/petshops.png",
  },
  {
    id: 7,
    area: "Vestuário",
    link: "https://www.google.com",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/vestuario.png",
  },
  {
    id: 8,
    area: "Lavanderias",
    link: "https://www.iguatemi.com.br",
    imageSrc: "https://partiu-static.s3.amazonaws.com/areas/lavanderias.png",
  },
  {
    id: 9,
    area: "Restaurantes",
    link: "https://www.planob.online",
    imageSrc:
      "https://partiu-static.s3.amazonaws.com/areas/AreaRestaurants.png",
  },
];

const CardCarousel = ({ id, area, link, imageSrc }) => {
  return (
    <Stack
      color="white"
      minWidth="200px"
      bgColor="white"
      alignItems="center"
      spacing={0}
      direction="column"
      justify="flex-start"
      borderRadius="20px"
      height="150px"
    >
      <Image
        width="100%"
        borderRadius="20px 20px 0px 0px"
        height="100px"
        objectFit="cover"
        src={imageSrc}
        alt="Imagem da área"
      />
      <Heading
        fontSize="10pt"
        margin="auto"
        height="50px"
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="black"
      >
        {area}
      </Heading>
    </Stack>
  );
};

export const VerticaisCarousel = () => {
  const carouselRef = useRef();

  useEffect(() => {
    if (!carouselRef.current) return;

    function checkVisible(elm) {
      var rect = elm.getBoundingClientRect();
      var viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }

    const interval = setInterval(() => {
      if (checkVisible(carouselRef.current))
        carouselRef.current.scrollLeft += 1;

      if (
        carouselRef.current.scrollLeft ===
        carouselRef.current.scrollWidth - carouselRef.current.offsetWidth
      ) {
        carouselRef.current.scrollLeft = 0;
      }
    }, 40);

    return () => clearInterval(interval);
  }, [carouselRef]);

  return (
    <Stack
      id="segments"
      direction="column"
      w="100%"
      alignItems="center"
      justify="center"
      bgGradient="linear-gradient(45deg, #DF2E54, #F86B36)"
      pt="30px"
      pb="70px"
      marginBottom="50px"
    >
      <Heading
        fontFamily="Chivo"
        fontSize="40px"
        textAlign="center"
        color="white"
        fontWeight="bold"
      >
        Segmentos de atuação
      </Heading>
      <Text
        fontSize="20px"
        textAlign="center"
        fontFamily="Chivo"
        fontWeight="normal"
        color="white"
        pb="20px"
      >
        Confira em que segmentos a Partiu! atua
      </Text>
      <Stack
        direction="column"
        ref={carouselRef}
        overflowX="hidden"
        width="100%"
        justifySelf="center"
      >
        <Stack
          width="100%"
          justifySelf="center"
          direction="row"
          align="center"
          justify="center"
          spacing={10}
          pb="5vh"
          pt="20px"
        >
          {CarouselData.map((area, index) => {
            return (
              <CardCarousel
                link={area.link}
                area={area.area}
                imageSrc={area.imageSrc}
                key={index}
              />
            );
          })}
        </Stack>
        <Stack direction="row" spacing={6} mt="4vh">
          {CarouselData_.map((area, index) => {
            return (
              <CardCarousel
                link={area.link}
                area={area.area}
                imageSrc={area.imageSrc}
                key={index}
              />
            );
          })}
        </Stack>
      </Stack>

      <HStack spacing={3}></HStack>
    </Stack>
  );
};
