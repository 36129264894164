import {
  Stack,
  Heading,
  Text,
  Image,
  List,
  ListItem,
  Box,
  ListIcon,
} from "@chakra-ui/react";
import { MdCheckCircle} from "react-icons/md";
import { CircleIcon } from "../atoms/CircleIcon";

const passos = [
  {
    index: 1,
    text: "Sua marca sendo associada a grandes empresas que já são nossas parceiras.",
  },
  {
    index: 2,
    text: "Seu estabelecimento é divulgado através do nosso site/aplicativo, mídias sociais, e-mail marketing e push.",
  },
  {
    index: 3,
    text: "Público diversificado em todo o pais.",
  },
  { index: 4, text: "Totalmente gratuito." },
];

const BoxIcon = (index) => {
  return (
    <Box
      w="auto"
      h="auto"
      borderRadius="90"
      bgColor="red"
      align="center"
      justify="center"
    >
      <Text>{index}</Text>
    </Box>
  );
};

export const WorldOfDiscounts = () => {
  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      w="90%"
      h="auto"
      align="center"
      justify="center"
      pb={{ base: "3vh", lg: "auto" }}
    >
      <Image
        src="https://partiu-static.s3.amazonaws.com/images/ClubeCelular.png"
        flex="1"
        alt="Como o seu clube ficara em seu dispositivo movel!"
        mt="-70px"
        objectFit="contain"
      />
      <Stack
        direction="column"
        align="center"
        justify="center"
        spacing={5}
        mr={{ base: "0px", lg: "-200px" }}
        ml={{ base: "0px", lg: "50px" }}
        pt={{ base: "3vh", lg: "null" }}
      >
        <Heading
          fontFamily="Chivo"
          color="#DF2E54"
          fontSize="35px"
          fontWeight="bold"
        >
          Aumente as suas vendas!
        </Heading>
        <Text
          fontFamily="Chivo"
          color="gray.500"
          fontWeight="medium"
          fontSize="18px"
        >
          Cadastre-se e faça seu estabelecimento ser visto por milhares de
          pessoas em nosso aplicativo e site. Veja as vantagens:
        </Text>
        <List spacing={4}>
          {passos.map((passo, index) => {
            return (
              <Stack direction="row" align="center" key={index}>
                <ListIcon as={MdCheckCircle} color="#DF2E54" w="20px" />
                <ListItem fontSize="20px" fontFamily="Chivo">
                  {passo.text}
                </ListItem>
              </Stack>
            );
          })}
        </List>
      </Stack>
    </Stack>
  );
};
