import React from "react";
import { ListIcon, ListItem } from "@chakra-ui/react";
import { BsCircleFill } from "react-icons/bs";
import { CircleIcon } from "./CircleIcon";
import {MdCheckCircle} from 'react-icons/md'
export const ListItens = (props) => {
  return (
    <>
      <ListItem fontSize="13pt" color="blackAlpha.700" fontWeight="medium">
      <ListIcon as={MdCheckCircle} color="#DF2E54" w="20px" />
        {props.text}
      </ListItem>
    </>
  );
};
