import { Box } from '@chakra-ui/react'
import React from 'react'

export function ShadowBox({ children, ...props }) {
    return <Box paddingTop="10px"
        paddingBottom="10px"
        paddingLeft="10px"
        paddingRight="10px"
        borderRadius="20"
        backgroundColor="#FAFAFA"
        boxShadow="lg"
        direction="column" {...props}>
        {children}
    </Box>
}