import { Select } from "@chakra-ui/select";
import React from "react";
import { Heading, VStack } from "@chakra-ui/layout";

export const SelectInput = ({ label, onChange, name, hookForm, options }) => (
  <VStack width="100%" alignItems="flex-start">
    <Heading
      textAlign="left"
      fontFamily="Montserrat"
      marginBottom="10px"
      fontSize="14px"
    >
      {label.toUpperCase()}
    </Heading>
    <Select name={name} onChange={onChange} {...hookForm}>
      {options.map((opt) => (
        <option value={opt.value}>{opt.name}</option>
      ))}
    </Select>
  </VStack>
);
