import React from "react";
import {Flex, Stack, Text, Button, Heading, Image, List, ListItem, Box, ListIcon} from '@chakra-ui/react'; 
import { NavBar } from "../../components/organisms/Header";
import { ImageSection } from "../../components/organisms/ImageSection";
import { Divisor } from "../../components/atoms/Divider";
import { MainButton } from "../../components/atoms/MainButtons";
import { DiscountExamples } from "../../components/organisms/DiscountExamples";
import {MdCheckCircle} from 'react-icons/md'
import { Footer } from "../../components/organisms/Footer";
import { DashboardSection } from "../../components/organisms/Dashboard";
import { Econometer } from "../../components/organisms/Econometer";
import { PromoVideo } from "../../components/organisms/PromoVideo";



const listItemsForYou = [
          {
                    text: "Lorem ipsum sit dolor amet."
          },
          {
                    text: "Consectetur adipiscing elit."
          },
          {
                    text: "Pellentesque in mollis augue."
          }
]



const Plans = () => {
          return(
                    <Stack direction={{base:"column", lg:"row"}} w="100%" p="5%" spacing={2} justify="space-evenly" align={{base:"center", lg:"flex-start"}}>
                              <Stack direction="column" align={{base:"center", lg:"flex-start"}} spacing={4} maxW="600px">
                              <Divisor/>
                              <Heading fontFamily="Chivo" fontWeight="medium" color='#DA2257'>
                                        Até 50% de Desconto em diversos estabelecimentos
                              </Heading>
                              <Text>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque in mollis augue. Cras rutrum scelerisque quam, non suscipit orci. 
                              </Text>
                              <List>
                                        <ListItem>
                                        <ListIcon as={MdCheckCircle} color="#DF2E54" w="20px" />
                                                  Lorem Ipsum sit dolor amet
                                        </ListItem>
                                        <ListItem>
                                        <ListIcon as={MdCheckCircle} color="#DF2E54" w="20px" />
                                                  Lorem Ipsum sit dolor amet
                                        </ListItem>
                                        <ListItem>
                                        <ListIcon as={MdCheckCircle} color="#DF2E54" w="20px" />
                                                  Lorem Ipsum sit dolor Amet
                                        </ListItem>
                              </List>
                              <MainButton isWhite={false} text="Iniciar teste grátis" />
                              </Stack>
                              <Stack direction={{base:"column", lg:"row"}} spacing={4} justify="center" alignItems="center">
                                        <Image position="absolute" zIndex="0" src="/elocal.png" maxW="600px" h="auto" alt="Venha ser Partiu!" display={{base:"none", lg:"unset"}} />
                              <PlanCard plan="Mensal" price="R$14.90" listitem1="30 dias grátis" listitem2="Economia sem complicação" listitem3="Uso diario e ilimitado" />
                              <PlanCard plan="Mensal" price="R$14.90" listitem1="30 dias grátis" listitem2="Economia sem complicação" listitem3="Uso diario e ilimitado" />
                              </Stack>
                    </Stack>
          )
}

const PlanCard = (props) => {
          return(
                    <Stack w="auto" bgColor="white" shadow="2xl" p="5%" alignItems="center" justify="center" position="relative" zIndex="1" spacing={4} direction="column" border="0px solid" borderColor="white" borderRadius="20">
                              <Text>{props.plan}</Text>
                              <Heading fontFamily="Chivo" color="#DA2257" fontWeight="medium" fontSize="30px">{props.price + "/Mês"}</Heading>
                              <List>
                             
                                        <ListItem>    
                                        <ListIcon as={MdCheckCircle} color="#DF2E54" w="20px" />
                                                  {props.listitem1}
                                        </ListItem>
                                        <ListItem>
                                        <ListIcon as={MdCheckCircle} color="#DF2E54" w="20px" />
                                                  {props.listitem2}
                                        </ListItem>
                                        <ListItem>
                                        <ListIcon as={MdCheckCircle} color="#DF2E54" w="20px" />
                                                  {props.listitem3}
                                        </ListItem>
                              </List>
                    </Stack>
          )
}

const HeaderFy = () => { 
          return(
                    <Flex w="100%" h="auto" bgGradient="linear(to-r, #BD1A34,#FA6D35)" direction={{base:"column-reverse", lg:"row"}} p="5% 10% 5% 10%" justify="space-between" alignItems="center" h="20%">
                              <Stack direction="column" textAlign="left" align="left" justify="center" spacing={4} maxW="600px">
                                        <Divisor/>
                                        <Heading fontFamily="Chivo" fontWeight="bold" color="white">
                                                  Para Você
                                        </Heading>
                                        <Text color="white">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque in mollis augue. Cras rutrum scelerisque quam, non suscipit orci. Vivamus aliquam fringilla leo, Cras rutrum scelerisque quam, non suscipit orci. Vivamus aliquam fringilla leo, 
                                        </Text>
                                        <List color="white">
                                                  <ListItem>
                                                  <ListIcon as={MdCheckCircle} color="#DF2E54" w="20px" />
                                                  Lorem Ipsum dolor sit amet.
                                                  </ListItem>
                                                  <ListItem>
                                                  <ListIcon as={MdCheckCircle} color="#DF2E54" w="20px" />
                                                  Consectetur adipiscing elit.
                                                  </ListItem>
                                                  <ListItem>
                                                  <ListIcon as={MdCheckCircle} color="#DF2E54" w="20px" />
                                                  Pellentesque in mollis augue.
                                                  </ListItem>
                                        </List>
                                        <MainButton isWhite={true} text="Seja Partiu!" />

                              </Stack>
                              <Image src="/compreonline.png" w="auto" maxH="650px" alt="Venha ser parte da Partiu!" />
 
                    </Flex>
          )
}



export default function ForYou(){
          return(
                    <>
                              <NavBar/>
                              <HeaderFy />
                              <ImageSection  hasDivisor={true} hasList={true} whichList={listItemsForYou} isReversed={false} hideButtons={false} src={'/elocal.png'} alt="Imagem de clientes da Partiu!" title="Economia nos melhores locais" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque in mollis augue. Cras rutrum scelerisque quam, non suscipit orci. "/>
                              <Plans />
                              <DiscountExamples />
                              <Econometer />
                              <PromoVideo />
                              <Footer/>
                    </>
          )
}