import { Box } from "@chakra-ui/layout";

export function RoundOverlay({ width = "102%", marginBottom = "-170px" }) {
  return (
    <Box
      backgroundColor="white"
      position="relative"
      width={width}
      height="200px"
      marginBottom={marginBottom}
      transform={{
        base: "translateY(-20%)",
        lg: "rotate(3deg) translateY(-20%) translateX(-2%);",
      }}
      zIndex="0px"
    >
      <Box
        width={{ base: "50px", lg: "300px" }}
        height={{ base: "100px", lg: "400px" }}
        backgroundColor="transparent"
        boxShadow={{ base: "0px 50px white", lg: "0px 200px white" }}
        borderBottomLeftRadius="200px"
        position="absolute"
        top={{ base: "-100px", lg: "-400px" }}
      />
      <Box
        width={{ base: "50px", lg: "400px" }}
        height={{ base: "100px", lg: "500px" }}
        backgroundColor="transparent"
        boxShadow={{ base: "0px 50px white", lg: "0px 300px white" }}
        borderBottomRightRadius="200px"
        position="absolute"
        right="0px"
        top={{ base: "-100px", lg: "-500px" }}
      />
    </Box>
  );
}
