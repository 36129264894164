import {
  Flex,
  Button,
  Image,
  HStack,
  VStack,
  Heading,
  Text,
  Stack,
} from "@chakra-ui/react";
import { BePartnerForm } from "../../pages/bepartner/components/BePartnerForm";
import { Card } from "../atoms/Card";

const CommerceTypes = (props) => {
  return (
    <HStack
      bgColor="#FF782D"
      h="70px"
      w="200px"
      p="10px"
      align="center"
      justify="center"
      borderRadius="10"
      shadow="lg"
      boxShadow="2xl"
    >
      <Image src={props.img} w="auto" h="auto" alt="logo" />
      <Text fontFamily="Chivo" fontWeight="bold" color="white">
        {props.text}
      </Text>
    </HStack>
  );
};

export const MainBePartner = () => {
  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      bgGradient="linear(to-t, #D82B60, #FFA800)"
      justify="space-evenly"
      p={{ base: "50px 5%", lg: "0px 5%" }}
      minHeight="650px"
      paddingTop="50px !important"
      id="top"
    >
      <VStack
        maxW="800px"
        justify="center"
        padding="50px 0px"
        mr={{ base: "0px", lg: "100px" }}
        align={{ base: "center", lg: "start" }}
        spacing={6}
      >
        <Heading color="white" fontFamily="Chivo" fontWeight="bold">
          Seja Parceiro
        </Heading>
        <Text
          color="white"
          fontSize="23px"
          fontFamily="Chivo"
          fontWeight="medium"
        >
          Divulgue sua marca e fidelize seus clientes. Amplie sua base de
          clientes com o Clube Partiu, são mais de 100.000 usuários cadastrados
          na plataforma!
        </Text>
        <Stack
          direction="row"
          align={{ base: "center", lg: "flex-start" }}
          justify="center"
        >
          <CommerceTypes
            text="E-commerce"
            img={
              "https://partiu-static.s3.amazonaws.com/icons/shopping-cart.png"
            }
          />
          <CommerceTypes
            text="E-commerce local"
            img={"https://partiu-static.s3.amazonaws.com/icons/ecommerce.png"}
          />
          <CommerceTypes
            text="Lojas Fisicas"
            img={"https://partiu-static.s3.amazonaws.com/icons/shop.png"}
          />
        </Stack>
      </VStack>
      <Card width={{ base: "100%", lg: "60%" }} alignSelf="center">
        <BePartnerForm />
      </Card>
    </Stack>
  );
};
