import { Button } from "@chakra-ui/button";
import { Checkbox } from "@chakra-ui/checkbox";
import { Input } from "@chakra-ui/input";
import {
  Box,
  Center,
  Divider,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/layout";
import { CircularProgress } from "@chakra-ui/progress";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { getCategories } from "../../../api/categories";
import { getCEP } from "../../../api/location";
import { sendPartnerEnquiry } from "../../../api/partners";
import { FormInput } from "../../../components/atoms/FormInput";
import { SelectInput } from "../../../components/atoms/SelectInput";
import { Title } from "../../../components/atoms/Title";
import { Discount } from "../../../components/molecules/Discount";
import { PercentageTag } from "../../../components/molecules/DiscountTag";
import ImageSelector from "../../../components/molecules/ImageSelector/ImageSelector";

const ConfirmData = ({ title, value }) => (
  <VStack spacing={1} alignItems="flex-start">
    <Heading
      textAlign="left"
      fontFamily="Montserrat"
      marginBottom="0px"
      fontSize="14px"
    >
      {title}
    </Heading>
    <Text fontWeight="500" color="black" fontSize="14px">
      {value}
    </Text>
  </VStack>
);

export const BePartnerForm = () => {
  const {
    register,
    watch,
    setValue,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [logo, setLogo] = useState({});
  const [image, setImage] = useState({});
  const [cepToLoad, setCepToLoad] = useState(null);
  const menuColor = "#D82B60";
  const menuFontColor = "white";
  const agreementName = "Partiu!";
  const id = null;
  const [step, setStep] = useState(0);
  const categoryRequest = useQuery("categories", () => getCategories());
  const sendEnquiryMutation = useMutation("sendEnquiry", sendPartnerEnquiry);

  watch((data, { name }) => {
    if (name !== "cep") return;

    let cep = data.cep.replace(/\_/g, "").replace(/\-/g, "");
    if (cep != null && cep.length == 8 && cep != cepToLoad) {
      setCepToLoad(cep);
    }
  });

  function send(data) {
    setStep(5);
    data["street"] =
      getValues("street") +
      " " +
      getValues("number") +
      " " +
      getValues("complement");

    if (data["agreement"] == true) data["agreement"] = id;
    else data["agreement"] = null;

    data["image"] = logo.value;
    data["discount_image"] = image.value;
    data["created_from"] = `Landing ${agreementName}`;

    sendEnquiryMutation.mutate(data);
  }

  function getErrorText() {
    const keys = Object.keys(errors);

    if (keys.length === 0) return <></>;

    return (
      <Text color="#ED4337" fontSize="14px" fontWeight="bold">
        {errors[keys[0]].message}
      </Text>
    );
  }

  function verify() {
    if (step === 0) {
      const email = getValues("email");
      const phone = getValues("cellphone");

      const emailVerification =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!emailVerification.test(email.toLowerCase())) {
        return setError("email", {
          type: "manual",
          message: "Email inválido",
        });
      }

      if (phone.indexOf("_") !== -1)
        return setError("cellphone", {
          type: "manual",
          message: "Telefone inválido",
        });
    } else if (step === 3) {
      if (!image?.value || !logo?.value)
        return setError("email", {
          type: "manual",
          message: "Por favor, preencha os campos de imagem",
        });
    }

    setStep(step + 1);
  }

  useEffect(() => {
    if (cepToLoad == null) return;

    getCEP(cepToLoad).then((data) => {
      setValue("street", `${data.logradouro} ${data.complemento}`);
      setValue("neighborhood", data.bairro);
      setValue("state", data.uf);
      setValue("city", data.localidade);
      setValue("complement", data.complemento);
    });
  }, [cepToLoad]);

  if (step === 0)
    return (
      <VStack spacing={0} width="100%" alignItems="flex-start">
        <Text fontSize="12px" color="#888">
          Passo 1/4
        </Text>
        <Title textAlign="left" fontWeight="500" color="black">
          Vamos começar?
        </Title>
        <form
          key="welcome-form"
          style={{ width: "100%", marginTop: 30 }}
          onSubmit={handleSubmit(verify)}
        >
          <VStack spacing={5} width="100%" alignItems="flex-start">
            <FormInput
              required
              hookForm={register("owner")}
              containerProps={{ width: "100%" }}
              label="Nome Completo*"
              placeholder="Nome Completo do Responsável da Loja"
            />
            <FormInput
              required
              hookForm={register("email")}
              containerProps={{ width: "100%" }}
              label="Email*"
              placeholder="Email para comunicação com a Loja"
            />
            <FormInput
              required
              hookForm={register("cellphone")}
              containerProps={{ width: "100%", paddingBottom: 3 }}
              label="Celular*"
              mask="(99) 9 9999-9999"
              placeholder="Celular para comunicação com o Responsável da Loja"
            />
            {getErrorText()}
            <Input
              backgroundColor={menuColor}
              color={menuFontColor}
              fontWeight="bold"
              type="submit"
              value="Continuar"
            />
          </VStack>
        </form>
      </VStack>
    );

  if (step === 1)
    return (
      <VStack
        className="animate__animated animate__fadeIn"
        spacing={0}
        width="100%"
        alignItems="flex-start"
      >
        <Text fontSize="12px" color="#888">
          Passo 2/4
        </Text>
        <Title textAlign="left" fontWeight="500" color="black">
          Sobre seu Estabelecimento
        </Title>
        <form
          key="store-form"
          style={{ width: "100%", marginTop: 30 }}
          onSubmit={handleSubmit(() => setStep(2))}
        >
          <VStack spacing={5} width="100%" alignItems="flex-start">
            <FormInput
              required
              hookForm={register("name")}
              containerProps={{ width: "100%" }}
              label="Nome da Empresa*"
              placeholder="Nome que será visível para os clientes"
            />
            <FormInput
              required
              hookForm={register("description")}
              containerProps={{ width: "100%" }}
              label="Descrição do Estabelecimento*"
              placeholder="Um breve texto sobre sua loja"
            />
            <SelectInput
              required
              options={
                categoryRequest.data.map((d) => ({
                  name: d.name,
                  value: d.id,
                })) || []
              }
              hookForm={register("field_of_activity")}
              label="Categoria*"
            />
            <FormInput
              hookForm={register("site")}
              containerProps={{ width: "100%" }}
              label="Site"
              placeholder="Endereço do site de seu estabelecimento"
            />
            {/* <Checkbox size="lg" colorScheme="green" {...register("agreement")}>
              Quero ser exclusivo Clube {agreementName}
            </Checkbox> */}
            <HStack width="100%">
              <Button onClick={() => setStep(0)}>Voltar</Button>
              <Input
                backgroundColor={menuColor}
                color={menuFontColor}
                fontWeight="bold"
                type="submit"
                value="Continuar"
              />
            </HStack>
          </VStack>
        </form>
      </VStack>
    );

  if (step === 2)
    return (
      <VStack
        className="animate__animated animate__fadeIn"
        spacing={0}
        width="100%"
        alignItems="flex-start"
      >
        <Text fontSize="12px" color="#888">
          Passo 3/4
        </Text>
        <Title textAlign="left" fontWeight="500" color="black">
          Endereço do seu Estabelecimento
        </Title>
        <form
          key="address-form"
          style={{ width: "100%", marginTop: 30 }}
          onSubmit={handleSubmit(() => setStep(3))}
        >
          <VStack spacing={5} width="100%" alignItems="flex-start">
            <FormInput
              required
              hookForm={register("cep")}
              containerProps={{ width: "100%" }}
              label="CEP*"
              placeholder="00000-000"
              mask="99999-999"
            />
            <HStack>
              <FormInput
                value={getValues("state")}
                required
                hookForm={register("state")}
                containerProps={{ width: "100%", flex: 1 }}
                label="Estado*"
                placeholder="RJ, SP, MG..."
              />
              <FormInput
                required
                hookForm={register("city")}
                containerProps={{ width: "100%", flex: 3 }}
                label="Cidade*"
                placeholder="Cidade"
              />
            </HStack>
            <FormInput
              required
              hookForm={register("neighborhood")}
              containerProps={{ width: "100%" }}
              label="Bairro*"
              placeholder="Bairro"
            />
            <HStack>
              <FormInput
                required
                hookForm={register("street")}
                containerProps={{ width: "100%", flex: 3 }}
                label="Endereço*"
                placeholder="Rua, Av, ..."
              />
              <FormInput
                required
                hookForm={register("number")}
                containerProps={{ width: "100%", flex: 1 }}
                label="Número*"
                placeholder="Número do estabelecimento"
              />
            </HStack>
            <FormInput
              hookForm={register("complement")}
              containerProps={{ width: "100%" }}
              label="Complemento"
              placeholder="Complemento do estabelecimento"
            />

            <HStack width="100%">
              <Button onClick={() => setStep(1)}>Voltar</Button>
              <Input
                backgroundColor={menuColor}
                color={menuFontColor}
                fontWeight="bold"
                type="submit"
                value="Continuar"
              />
            </HStack>
          </VStack>
        </form>
      </VStack>
    );

  if (step === 3)
    return (
      <VStack
        className="animate__animated animate__fadeIn"
        spacing={0}
        width="100%"
        alignItems="flex-start"
      >
        <Text fontSize="12px" color="#888">
          Passo 4/4
        </Text>
        <Title fontWeight="500" color="black">
          Detalhes do Desconto
        </Title>
        <form
          key="address-form"
          style={{ width: "100%", marginTop: 30 }}
          onSubmit={handleSubmit(verify)}
        >
          <VStack spacing={5} width="100%" alignItems="flex-start">
            <SelectInput
              required
              label="Desconto Oferecido"
              hookForm={register("percentage")}
              containerProps={{ width: "100%" }}
              options={new Array(10).fill(0).map((v, i) => ({
                value: (i + 1) * 10,
                name: `${(i + 1) * 10}% de desconto em toda loja`,
              }))}
            />
            <HStack width="100%">
              <ImageSelector
                label="Logo da Empresa"
                name="image"
                image={logo.value}
                onChange={(image) => {
                  setLogo(image);
                }}
              />
              <ImageSelector
                label="Imagem do Produto"
                name="discount_image"
                image={image.value}
                onChange={(image) => {
                  setImage(image);
                }}
              />
            </HStack>
            {getErrorText()}
            <HStack width="100%">
              <Button onClick={() => setStep(2)}>Voltar</Button>
              <Input
                backgroundColor={menuColor}
                color={menuFontColor}
                fontWeight="bold"
                type="submit"
                value="Verificar Dados"
              />
            </HStack>
          </VStack>
        </form>
      </VStack>
    );

  if (step === 4)
    return (
      <VStack
        className="animate__animated animate__fadeIn"
        spacing={0}
        width="100%"
        alignItems="flex-start"
      >
        <Text fontSize="12px" color="#888">
          Resumo
        </Text>
        <Title fontWeight="500" color="black">
          Falta Pouco!
        </Title>
        <Title fontWeight="500" color="black" fontSize="14px">
          Confirme seus dados de contato abaixo
        </Title>

        <VStack
          paddingTop="20px"
          width="100%"
          alignItems="flex-start"
          spacing={5}
        >
          <ConfirmData title="Nome Completo" value={getValues("owner")} />
          <ConfirmData title="Email" value={getValues("email")} />
          <ConfirmData title="Celular" value={getValues("cellphone")} />

          <Divider />

          <Title fontWeight="500" color="black" fontSize="14px">
            Veja como sua loja aparecerá no Clube {agreementName}
          </Title>
          <Box paddinTop="30px" width="184px">
            <Discount
              mode="físico"
              image={image.value}
              title={getValues("name")}
              subTitle={getValues("street")}
              tag={<PercentageTag percentage={getValues("percentage")} />}
            />
          </Box>

          <form
            key="address-form"
            style={{ width: "100%" }}
            onSubmit={handleSubmit((d) => send(d))}
          >
            <HStack width="100%">
              <Button onClick={() => setStep(3)}>Voltar</Button>
              <Input
                backgroundColor={menuColor}
                color={menuFontColor}
                fontWeight="bold"
                type="submit"
                value="Enviar"
              />
            </HStack>
          </form>
        </VStack>
      </VStack>
    );

  if (step === 5) {
    if (sendEnquiryMutation.isIdle || sendEnquiryMutation.isLoading)
      return (
        <VStack
          className="animate__animated animate__fadeIn"
          spacing={0}
          width="100%"
          alignItems="flex-start"
        >
          <Title fontWeight="500" color="black">
            Enviando informações...
          </Title>
          <Center>
            <CircularProgress isIndeterminate />
          </Center>
        </VStack>
      );
    return (
      <VStack
        className="animate__animated animate__fadeIn"
        spacing={10}
        width="100%"
        alignItems="flex-start"
      >
        <Title fontWeight="500" color="black">
          Agora é só esperar!
        </Title>
        <Text textAlign="left">
          Sua solicitação de parceria foi realizada com sucesso!
          <br />
          Analisaremos rapidinho e lhe daremos o retorno em breve no email
          fornecido.
          <br />
        </Text>
      </VStack>
    );
  }
};
