// Next.js API route support: https://nextjs.org/docs/api-routes/introduction

import axios from "axios";

export function createContact({ name, email, cellphone }) {
  return axios.post("https://api.partiu.com.br/api/v1/contact/", {
    name,
    email,
    cellphone: cellphone,
    subject: "Quero o Clube",
    message: "Quero receber uma ligação!",
    landing: "app",
    origin: "site"
  });
}
