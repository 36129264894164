import {
  Text,
  Flex,
  Heading,
  Box,
  Stack,
  Button,
  Grid,
  Spacer,
  useDisclosure,
  Menu,
  Center,
  MenuButton,
  MenuItem,
  MenuList,
  Link,
  Divider,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { MdExpandMore } from "react-icons/md";
import { useCheckMobile } from "../../utils/CheckMobile";
import Logo from "../atoms/Logo";

export const NavBar = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useCheckMobile();
  const bgVideo = props.bgVideo;
  const [isTransparent, setIsTransparent] = useState(props.isTransparent);

  const onScroll = (event) => {
    if (window.scrollY > 500) {
      setIsTransparent(false);
    } else {
      setIsTransparent(true);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
  });

  const ParaEmpresas = (props) => {
    return (
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<MdExpandMore />}
          bgColor="transparent"
          color="white"
          _hover={{
            bgColor: "transparent",
          }}
          _active={{
            bgColor: "transparent",
          }}
          fontSize="16px"
          fontFamily="Chivo"
          fontWeight="bold"
          display={{ base: "none", lg: "flex" }}
        >
          para sua empresa
        </MenuButton>
        <MenuList bgColor="gray.100" border="0px" borderColor="transparent">
          <Divider orientation="vertical" h="auto" />
          {SubLinks.map((subLink, index) => {
            return (
              <Link key={index} href={subLink.url}>
                <MenuItem color="black">{subLink.title}</MenuItem>
              </Link>
            );
          })}
        </MenuList>
      </Menu>
    );
  };
  const MobileMenu = () => {
    return (
      <Menu>
        <MenuButton
          position="absolute"
          left="10%"
          top="15%"
          as={IconButton}
          aria-label="Menu"
          icon={<AiOutlineMenu color="white" width="45px" fontSize="30px" />}
          backgroundColor="transparent"
        />
        <MenuList bgColor="gray.100" borderColor="transparent" color="black">
          {SubLinks.map((link, index) => {
            return (
              <Link key={index} href={link.url}>
                <MenuItem color="black">{link.title}</MenuItem>
              </Link>
            );
          })}
          <Link
            rel="noreferrer"
            href="https://app.partiu.com.br/contato"
            target="_blank"
          >
            <MenuItem color="black">contato</MenuItem>
          </Link>
          <Link
            rel="noreferrer"
            href="https://app.partiu.com.br/cadastrar-loja"
            target="_blank"
          >
            <MenuItem color="black">seja parceiro</MenuItem>
          </Link>
          <Center>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://app.partiu.com.br/rede"
            >
              <Button
                bgColor="#fc195a"
                color="white"
                w="100%"
                alignSelf="auto"
                fontSize="20px"
                fontFamily="Chivo"
                fontWeight="bold"
              >
                Parceiros
              </Button>
            </Link>
          </Center>
        </MenuList>
      </Menu>
    );
  };

  const SubLinks = [
    {
      title: "para seu cliente",
      url: "/#forClient",
    },
    {
      title: "para seu funcionário",
      url: "/#forEmployee",
    },
    {
      title: "para sua associação",
      url: "/#forAssociation",
    },
    {
      title: "segmentos de atuação",
      url: "/#segments",
    },
  ];

  const _Links = [
    {
      title: "demonstração",
      url: "/demonstracao",
    },
    {
      title: "seja parceiro",
      url: "/seja-parceiro",
    },
    {
      title: "quem somos",
      url: "/quem-somos",
    },
    {
      title: "contato",
      url: "https://app.partiu.com.br/contato",
    },
  ];

  return (
    <>
      {bgVideo ? (
        <Box position="absolute" left="0" top="0" h="100%">
          {props.bgVideo}
        </Box>
      ) : (
        <></>
      )}

      <Flex
        as="nav"
        zIndex="30"
        position="fixed"
        wrap="wrap"
        w="100%"
        justifyContent="center"
        align="center"
        bgGradient="linear(to-b, rgba(50,50,50,0.7), rgba(50,50,50,0))"
        top="0px"
      >
        <Box
          transition="0.5s"
          position="absolute"
          width="100%"
          height="100%"
          opacity={isTransparent ? 0 : 1}
          background="linear-gradient(90deg, #D82B60, #FFA800)"
          boxShadow="0px 0px 10px rgba(0,0,0,0.6)"
        />
        <Flex
          w={{ base: "95%", lg: "95%" }}
          direction={{ base: "column", lg: "row" }}
          alignSelf="center"
          margin="auto"
          alignItems="center"
          h="100%"
          position="relative"
          justifyContent={{ base: "center", lg: "flex-start" }}
        >
          <Flex ml={{ base: "0px", lg: "150px" }} mt="10px" mb="10px">
            {isMobile ? (
              <Box justifySelf="flex-start">
                <MobileMenu />{" "}
              </Box>
            ) : (
              <></>
            )}
            <Link href="/">
              <Logo />
            </Link>
          </Flex>
          <Spacer />
          <ParaEmpresas />
          {!isMobile ? (
            <HStack spacing={10}>
              {_Links.map((link, index) => {
                return (
                  <Box key={index}>
                    <a
                      target={
                        link.url.indexOf("https") === -1 ? "_self" : "_blank"
                      }
                      rel="noreferrer"
                      href={link.url}
                    >
                      <Text
                        color="white"
                        fontSize="16px"
                        fontWeight="extrabold"
                        fontFamily="Chivo"
                      >
                        {link.title}
                      </Text>
                    </a>
                  </Box>
                );
              })}

              <Box>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.partiu.com.br/rede"
                >
                  <Button
                    bgColor="#fc195a"
                    color="white"
                    mt={{ base: "3vh", lg: "0" }}
                    fontSize="18px"
                    fontFamily="Chivo"
                    fontWeight="bold"
                  >
                    Parceiros
                  </Button>
                </a>
              </Box>
            </HStack>
          ) : (
            <></>
          )}
        </Flex>
        <Spacer />
      </Flex>
    </>
  );
};
