import React from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  HStack,
  Spacer,
  Divider,
  Heading,
  VStack,
  Stack,
} from "@chakra-ui/react";
import { Divisor } from "../atoms/Divider";
import { useCheckMobile } from "../../utils/CheckMobile";
import Rotate from "react-reveal/Rotate";

const Products = [
  {
    id: "1",
    name: "Tênis Nike",
    url: "https://partiu-static.s3.amazonaws.com/images/ExemploDescontoTenis.png",
    price: 300.0,
    discount: 15,
    tag: "https://partiu-static.s3.amazonaws.com/images/45reais.png",
    logoUrl: "https://partiu-static.s3.amazonaws.com/Logos/netshoes.png",
  },
  {
    id: "2",
    name: "3 peças de roupa",
    url: "https://partiu-static.s3.amazonaws.com/images/ExemploDescontoVestido.png",
    price: 250,
    discount: 20,
    tag: "https://partiu-static.s3.amazonaws.com/images/50reais.png",
    logoUrl: "https://partiu-static.s3.amazonaws.com/Logos/marisa.png",
  },
  {
    id: "3",
    name: "Sofá Cama",
    url: "https://partiu-static.s3.amazonaws.com/images/ExemploDescontoSofa.png",
    price: 1200,
    discount: 10,
    tag: "https://partiu-static.s3.amazonaws.com/images/120reais.png",
    logoUrl: "https://partiu-static.s3.amazonaws.com/Logos/mobly.png",
  },
];

let formatMoney = new Intl.NumberFormat([], {
  style: "currency",
  currency: "BRL",
});

const Examples = () => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      w="100%"
      pt={{ base: "5px", lg: "30px" }}
    >
      <Divisor />
      <Box whiteSpace="nowrap" pt="20px" justifyContent="center">
        <Heading
          fontSize="28px"
          fontWeight="extrabold"
          color="gray.700"
          whiteSpace="nowrap"
          display="flex"
        >
          Exemplos de{" "}
          <Heading fontSize="28px" color="#DA2257" pl="5px">
            {" "}
            descontos{" "}
          </Heading>
        </Heading>
      </Box>
      <Box textAlign="center" pt="10px" pb="30px">
        <Heading
          fontSize="24px"
          fontWeight="medium"
          color="gray.500"
          lineHeight="20px"
        >
          Comece a economizar{" "}
          <Heading
            fontSize="24px"
            fontWeight="medium"
            color="gray.500"
            textAlign="center"
          >
            agora!
          </Heading>
        </Heading>
      </Box>
    </Flex>
  );
};

const EasyToUse = () => {
  return (
    <Stack direction="column" w="300px">
      <Heading
        fontFamily="Chivo"
        fontWeight="bold"
        fontSize="20pt"
        color="#D91F59"
      >
        Fácil de usar e sem limite de utilização
      </Heading>
      <Text color="gray.600" fontSize="14pt">
        Com possibilidade de estender para dependentes no plano família
      </Text>
    </Stack>
  );
};

const DiscountCard = (props) => {
  return (
    <Flex
      direction="column"
      borderRadius="15px"
      align="center"
      justify="center"
      w="230px"
      transition="0.2s"
      boxShadow="0 10px 10px rgb(0 0 0 / 20%)"
      p="10px"
      _hover={{
        shadow: "2xl",
        transform: "translateY(-5px)",
      }}
      position="relative"
      pb="20px"
    >
      <Box pt="20px" pb="20px">
        <Image w="100px" src={props.logourl} alt="Logo do desconto" />
        <Box
          backgroundColor="#DA2257"
          width="50px"
          height="50px"
          color="white"
          fontWeight="bold"
          borderRadius="6000px"
          position="absolute"
          top={{ base: "70px", lg: "75px" }}
          left={{ base: "82px", lg: "50px" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="2"
        >
          {props.discount}
          <Text fontSize="12px" fontFamily="Chivo">
            %
          </Text>
        </Box>
        <Box
          width={{ base: "30px", lg: "40px" }}
          height={{ base: "auto", lg: "40px" }}
          color="white"
          fontWeight="bold"
          borderRadius="6000px"
          position="absolute"
          top={{ base: "100px", lg: "90px" }}
          left={{ base: "10px", lg: "-20px" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="1"
        >
          <Image
            src={props.tag}
            w="auto"
            h="auto"
            alt="Tag with the discount price"
            position="fixed"
            zIndex={{ base: "0", lg: "0" }}
          />
        </Box>
      </Box>
      <Spacer />
      <Box>
        <Image
          src={props.imageurl}
          h="150px"
          w="auto"
          alt="Produto com desconto"
        />
      </Box>
      <Box>
        <Text fontSize="18pt" fontFamily="Chivo" fontWeight="medium">
          {props.name}
        </Text>
      </Box>

      <Box textAlign="center">
        <Text fontSize="10pt" fontFamily="Chivo" pb="20px">
          Seu cliente ou funcionário pagará
        </Text>
        <Text
          as="del"
          color="red"
          fontSize="18pt"
          fontWeight="bold"
          fontFamily="Chivo"
        >
          {formatMoney.format(props.price)}
        </Text>
        <Text
          fontSize="24pt"
          color="green"
          fontWeight="bold"
          fontFamily="Chivo"
        >
          {formatMoney.format(
            props.price - (props.price * props.discount) / 100
          )}
        </Text>
      </Box>
    </Flex>
  );
};

export const DiscountExamples = () => {
  const isMobile = useCheckMobile();
  return (
    <VStack paddingBottom="100px">
      <Examples />
      <Stack
        spacing={5}
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
        align="center"
        margin="auto"
        w="80%"
        bgColor="white"
        mb="50px"
      >
        <Stack direction={{ base: "column", lg: "row" }} spacing={7}>
          {Products.map((product, index) => {
            return (
              <>
                <Rotate bottom right>
                  <DiscountCard
                    logourl={product.logoUrl}
                    name={product.name}
                    imageurl={product.url}
                    price={product.price}
                    discount={product.discount}
                    tag={product.tag}
                    key={index}
                  />
                </Rotate>
              </>
            );
          })}
        </Stack>
        <EasyToUse />
        {isMobile ? <Divisor /> : <></>}
      </Stack>
    </VStack>
  );
};
