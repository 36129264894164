import React from "react";
import { Flex, Heading, Stack, Text, Button, Box } from "@chakra-ui/react";
import { ForButtons } from "../atoms/ForButtons";
import { DoubleButtons } from "../atoms/DoubleButtons";
import { MainButton } from "../atoms/MainButtons";

export const ForYourCompany = () => {
  return (
    <Stack
      direction="column"
      alignContent="center"
      justify="center"
      justifyContent="center"
      textAlign="center"
      w="100%"
      bgGradient="linear(to-t, #D82B60, #FFA800)"
      p="30px 10px"
      spacing={3}
      mb={{ base: "auto", lg: "50px" }}
    >
      <Heading
        color="white"
        fontSize="30pt"
        fontWeight="extrabold"
        fontFamily="Chivo"
      >
        Para a sua empresa
      </Heading>
      <Heading
        color="white"
        fontSize="18pt"
        fontWeight="bold"
        fontFamily="Chivo"
      >
        Agende uma demonstração agora!
      </Heading>
      <Text
        color="white"
        fontSize="12pt"
        fontWeight="medium"
        fontFamily="Chivo"
      >
        Selecione o perfil do seu público e em breve entraremos em contato com
        você!
      </Text>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={5}
        align="center"
        justify="center"
        pt="20px"
        pb="20px"
      >
        <ForButtons
          src={"https://partiu-static.s3.amazonaws.com/icons/clienticon.png"}
          text="Para seu cliente"
          url="#forClient"
        />
        <ForButtons
          src={"https://partiu-static.s3.amazonaws.com/icons/employeeicon.png"}
          text="Para seu funcionário"
          url="#forEmployee"
        />
        <ForButtons
          src={
            "https://partiu-static.s3.amazonaws.com/icons/associationicon.png"
          }
          text="Para sua associação"
          url="#forAssociation"
        />
      </Stack>
    </Stack>
  );
};
