import { axiosInstance } from "./axios";

export const getCategories = (params = {}) => {
  let agreement = params.agreementId;
  let url = "/fields_of_activity/";

  if (agreement && agreement !== "null") {
    url += "?agreement=" + agreement;
  }
  return axiosInstance.get(url).then(({ data }) => data);
};
