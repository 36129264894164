import React from "react";
import { Flex, Heading, Box, Image, Stack } from "@chakra-ui/react";
import { Divisor } from "../atoms/Divider";



export const Econometer = () => {
  return (
    <Stack w="100%" align="center" justify="center" direction="column" p="20px" mb="20px">
      <Box mt="0px" alignContent="center" justifyContent="center"> 
        <Heading
          textAlign="center"
          color="gray.700"
          fontWeight="Chivo"
        >
          Após a utilização veja como seu cliente observará o

        </Heading>

      </Box>
      <Heading color="#D91F59" pl="5px" pb="20px" alignSelf="center" justifySelf='center'>
      Econômetro
    </Heading>
      <Box p={{ base: "50px", lg: "1% 20% 0% 20%" }}>
        <Image
          src="https://partiu-static.s3.amazonaws.com/images/economize.png"
          maxW="680px"
          h="auto"
          alt="Imagens do nosso Econometro."
        />
      </Box>
    </Stack>
  );
};
