import React from "react";
import { Heading } from "@chakra-ui/layout";

export const Title = ({ children, ...props }) => (
  <Heading
    fontFamily="Raleway"
    fontSize="16px"
    fontWeight="bold"
    textAlign={{ base: "center", lg: "left" }}
    color="black"
    textTransform="uppercase"
    {...props}
  >
    {children}
  </Heading>
);
