import { Box, Flex, Heading, HStack, Text, } from "@chakra-ui/react";
import React from "react";
import { Divisor } from "../atoms/Divider";
import { DoubleButtons } from "../atoms/DoubleButtons";
import { MainButton } from "../atoms/MainButtons";
import { LeadForm } from "../molecules/LeadForm";
import { ReceiveCallForm } from "../molecules/ReceiveCallForm";
import { NavBar } from "./Header";

const videoStyle = {
  objectFit: "cover",
  width: "100%",
  height: "100%",
  position: "absolute",
  top: "0",
  left: "0",
  zIndex: "-1",
};

const DaysTitleStyled = ({ children }) => (
  <span
    style={{
      backgroundColor: "purple",
      height: "auto",
      width: "30px",
      textAlign: "center",
      borderRadius: "90px",
      fontSize: "35px",
      fontWeight: "bold",
      padding: "2px 3px",
    }}
  >{children}</span>
)

export const HomeMainBox = ({ hideForm }) => {
  return (
    <>
      <Flex
        position="relative"
        w="100%"
        minH={{ base: "700px", lg: "650px" }}
        direction="column"
      >
        <video
          autoPlay
          loop
          muted
          preload="auto"
          style={{
            zIndex: -1,
            position: "relative",
            objectFit: "fill",
            ...videoStyle,
          }}
        >
          <source
            src="https://partiu-static.s3.amazonaws.com/videos/home-cover-compressed.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <NavBar isTransparent={true} />

        <Flex
          pl={{ base: "0%", lg: "10%" }}
          w={{ base: "90%", lg: "50%" }}
          margin={{ base: "auto", lg: "initial" }}
          mt={{ base: "150px", lg: "initial" }}
          pt={{ base: "0px", lg: "150px" }}
          textAlign="left"
          direction="column"
          justify="center"
          textShadow={{
            base: "0px 0px 10px rgba(0,0,0,0.8)",
            lg: "0px 0px 10px rgba(0,0,0,0.4)",
          }}
        >
          <Divisor />
          <Heading
            fontSize={{ base: "24pt", lg: "27pt" }}
            fontFamily="Chivo"
            fontWeight="bold"
            color="white"
          >
            CONTRATE AGORA
          </Heading>
          <Heading
            fontSize={{ base: "18pt", lg: "21pt" }}
            fontFamily="Chivo"
            fontWeight="medium"
            color="white"
            flexDirection="row"
          >
            e tenha um PROGRAMA DE RELACIONAMENTO com a identidade visual da sua empresa, integrado ao seu site e/ou app em <DaysTitleStyled>3</DaysTitleStyled> dias.
          </Heading>
          {!hideForm ?
            <Box mt="0px">
              <HStack alignItems="center" pt="20px" spacing="4">
                <Box>
                  <LeadForm />
                </Box>
              </HStack>
            </Box> : <></>}
        </Flex>
      </Flex>
    </>
  );
};
