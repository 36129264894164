import React from "react";
import { Button, Flex, Stack, Image, Heading, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Neymar } from "../molecules/Neymar";
import { ShadowBox } from "../molecules/ShadowBox";

export const CircularCarousel = (props) => {
  const listItens = [
    {
      key: "management",
      text: "Parcerias nacionais - Através do Clube de Vantagens Partiu os usuários terão acessos a benefícios exclusivos oferecidos por grandes marcas como Marisa, Casas Bahia, Netshoes, O Boticário, Centauro, e muito mais!",
      title: "Parcerias Nacionais",
    },
    {
      key: "gps",
      text: "Parcerias Físicas - Uma das formas de deixar o seu Clube personalizado é poder oferecer benefícios em locais relevantes da sua região. Pensando nisso, nosso time incentiva o comercio de cada localidade a fazer parte do nosso Clube. O convite e treinamento são oferecidos a eles gratuitamente em troca de benefícios exclusivos e relevantes a nossos usuários.",
      title: "Parcerias Físicas",
    },
    {
      key: "cpu",
      text: "Tecnologia white label - Um ecossistema de tecnologia completo que conecta o benefício de desconto na conta dos melhores locais a usuários de empresas. A partir do momento do login, o aplicativo reconhece o cliente e “personaliza” todo o front-end com a identidade visual da empresa. ",
      title: "Tecnologia White Label",
    },
    {
      key: "customer-service",
      text: "Suporte ao usuários - Dúvidas, sugestões, reclamações fazem parte de todo e qualquer negócio e a ouvidoria da Partiu Vantagens está disponível e acessível em todas as plataformas, de segunda a sexta-feira, das 9h às 18h.",
      title: "Suporte aos usuários",
    },
    {
      key: "choice",
      text: "Relacionamento - Estar em contato com usuário é fundamental para mantê-lo por dentro das promoções, novidades e destaques. A Partiu! gerencia toda régua de comunicação para que seja sempre criado um conteúdo relevante e estratégico para o Clube.",
      title: "Relacionamento",
    },
    {
      key: "megaphone",
      text: "Curadoria de Novas Parcerias - Através da seção Seja Parceiro é possível que os usuários indiquem estabelecimentos locais na sua região para fazer parte do clube. Nós convidamos, negociamos as vantagens e realizamos o treinamento do estabelecimento indicado.",
      title: "Curadoria de novas Parcerias",
    },
  ];

  const [selection, setSelection] = useState(listItens[0]);

  return (
    <Stack
      width={{ base: "100%", lg: "90%" }}
      margin="auto"
      direction={{ base: "column", lg: "row" }}
      mb="100px"
      position="relative"
      zIndex="2px"
      spacing={2}
    >
      <Flex direction="column" w="100%" align="center">
        <Heading textAlign="center" fontFamily="Chivo" color="#DA2257">
          O que a Partiu! oferece?
        </Heading>
        <Text textAlign="center" fontFamily="Chivo" pb="50px" fontSize="20px">
          Clique e conheça mais sobre nossos serviços
        </Text>
        <Neymar
          value={selection.key}
          onChange={(key) =>
            setSelection(listItens.filter((k) => k.key === key)[0])
          }
        />
      </Flex>
      <ShadowBox
        direction="column"
        w={{ lg: "1200px", base: "auto" }}
        justify="start"
        align="center"
        spacing={8}
        textAlign="center"
      >
        <Heading
          fontFamily="Chivo"
          color="#DA2257"
          fontSize="30px"
          fontWeight="bold"
          textAlign="justify"
          alignSelf="center"
          p="15px"
        >
          {selection.title}
        </Heading>
        <Text textAlign="center" fontSize="20px" p="10px">
          {selection.text}
        </Text>
      </ShadowBox>
    </Stack>
  );
};
