import { ImageSection } from "../../components/organisms/ImageSection";
import { Footer } from "../../components/organisms/Footer";

import { NavBar } from "../../components/organisms/Header";
import { MainBox } from "../../components/organisms/MainBox";
import { Promessa } from "../../components/organisms/Promessa";
import { TestimonyCarousel } from "../../components/organisms/TestimonyCarousel";
import { DiscountExamples } from "../../components/organisms/DiscountExamples";
import { FixedButtons } from "../../components/molecules/FixedButtons";
import { useCheckMobile } from "../../utils/CheckMobile";
import { Box, useDisclosure, VStack } from "@chakra-ui/react";
import { DoubleButtons } from "../../components/atoms/DoubleButtons";
import { ReceiveCall } from "../../components/molecules/ReceiveCall";
import { MobileDrawer } from "../../components/organisms/MobileDrawer";
import { openPopupWidget } from "react-calendly";

export default function Provedores() {
  const receiveCallDisclosure = useDisclosure();
  const isMobile = useCheckMobile();

  const listText = [
    {
      text: "Campanhas semanais personalizadas",
    },
    {
      text: "Divulgação de novas parcerias",
    },
    {
      text: "Incentivo ao uso da plataforma",
    },
    {
      text: "Comunicação através de push notifications",
    },
    {
      text: "E muito mais.",
    },
  ];

  const listText2 = [
    {
      text: "Auxilia na tomada de decisões",
    },
    {
      text: "Otimização de tempo e recursos",
    },
    {
      text: "Transparência de informações",
    },
    {
      text: "Alinhamento estratégico",
    },
  ];
  const preFill = {
    name: "Jon Snow",
    firstName: "Jon",
    lastName: "Snow",
    email: "test@test.com",
  };

  const url = "https://calendly.com/prevendas-bju/reuniao-partiu";

  const pageSettings = {
    backgroundColor: "cbe5e2",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
  };

  const onClick = () => openPopupWidget({ url, preFill, pageSettings });

  return (
    <div>
      <nav>
        <NavBar />
      </nav>

      <Box margin="auto" width="100%" spacing={0} alignItems="center">
        <ReceiveCall {...receiveCallDisclosure} />
        <MainBox
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
        />
        <Box
          backgroundColor="white"
          position="relative"
          width="102%"
          height="200px"
          marginBottom="-170px"
          transform={{
            lg: "rotate(3deg) translateX(-2%);",
          }}
          zIndex="0px"
        >
          <Box
            width={{ base: "50px", lg: "300px" }}
            height={{ base: "100px", lg: "400px" }}
            backgroundColor="transparent"
            boxShadow={{ base: "0px 50px white", lg: "0px 200px white" }}
            borderBottomLeftRadius="200px"
            position="absolute"
            top={{ base: "-100px", lg: "-400px" }}
          />
          <Box
            width={{ base: "50px", lg: "400px" }}
            height={{ base: "100px", lg: "500px" }}
            backgroundColor="transparent"
            boxShadow={{ base: "0px 50px white", lg: "0px 300px white" }}
            borderBottomRightRadius="200px"
            position="absolute"
            right="0px"
            top={{ base: "-100px", lg: "-500px" }}
          />
        </Box>
        <div
          transform="translateY(-20px)"
          width="100%"
          alignItems="center"
          margin="auto"
          spacing={20}
        >
          <Promessa />

          <ImageSection
            isReversed={false}
            hasDivisor={true}
            hasList={false}
            src={
              "https://partiu-static.s3.amazonaws.com/images/ImagemVerticais.png"
            }
            title="Seus clientes conectado com  grandes marcas!"
            alt="Exemplos de descontos que a Partiu oferece."
            text="Através do clube de vantagens com a identidade visual da sua empresa, os seus clientes estarão conectados a milhares de estabelecimentos físicos e ecommerces com descontos exclusivos."
          />
          <ImageSection
            isReversed={true}
            hasList={false}
            hasDivisor={true}
            title="Reduza custos ao oferecer o clube para seus clientes!"
            alt="Imagem de várias pessoas trabalhando em conjunto."
            text="Se você não usa o SVA, ofereça aos seus clientes um clube de vantagens completo e ainda tenha a possibilidade de redução tributária.
          Caso já utilize o SVA e já tenha a redução tributária, com a Partiu você ainda terá uma economia ainda maior através do valor de repasse que será reduzido.
          "
            src={"https://partiu-static.s3.amazonaws.com/images/ExemploSVA.png"}
          />
          <ImageSection
            isReversed={false}
            hasList={true}
            hasDivisor={true}
            whichList={listText}
            src={
              "https://partiu-static.s3.amazonaws.com/images/ExemploSVA2.png"
            }
            alt="Exemplos de campanhas informativas que a Partiu oferece para os clientes."
            title="Exemplo de como os provedores comunicam para o seu cliente."
            text=" Com o Clube de Vantagens você terá: "
          />
          <ImageSection
            isReversed={true}
            hasList={false}
            hasDivisor={true}
            src={"https://partiu-static.s3.amazonaws.com/images/economize.png"}
            title="Após a utilização, veja como seu cliente observará o Econômetro."
            text="Através do econômetro, o cliente poderá acompanhar a economia realizada em cada compra."
          />
          <DiscountExamples />

          <ImageSection
            isReversed={true}
            hasList={true}
            whichList={listText2}
            hasDivisor={true}
            src={
              "https://partiu-static.s3.amazonaws.com/images/computador1.png"
            }
            text="Nós disponibilizamos um painel de controle completo, que permite ter uma visão geral dos hábitos de interesse e consumo de seus clientes."
            title="Entenda melhor o comportamento do seu cliente com o dashboard."
          />
        </div>
        <TestimonyCarousel
          items={[
            {
              text: "A Partiu está conosco há 4 anos e sempre tivemos um atendimento muito presente, buscando agregar valor para nossos colaboradores e clientes tanto na administradora quanto na imobiliária.",
              author: "Marcelo Borges",
              job: "Diretor Administrativo ML Administradora",
              image: "marceloml.png",
              logo: "https://partiu-static.s3.amazonaws.com/Logos/ml-administradora.png",
              company: "ML Administradora",
            },
            {
              text: "Iniciamos a parceria em 2017. Ao longo dos anos percebemos sempre a constante evolução no produto, trazendo novas parcerias relevantes para síndicos e condôminos.",
              author: "Dennys Teles",
              job: "Diretor administrativo Atlântida",
              image:
                "https://partiu-static.s3.amazonaws.com/faces/dennysatlantida.png",
              logo: "https://partiu-static.s3.amazonaws.com/Logos/atlantida.png",
              company: "Atlantida Administradora",
            },
            {
              text: "A experiência com a Partiu Vantagens tem sido transformadora em nosso empreendimento, tando para o nosso negócio, quanto para as pessoas.",
              author: "Danilo",
              job: "Diretor - UBANNET",
              image:
                "https://cdn.discordapp.com/attachments/1042581944879284317/1101599735942492182/366c32d6-e102-4f98-83fc-03f175eececf.png",
              logo: "https://partiu-rest-s3.s3.sa-east-1.amazonaws.com/logos/Logo-Uba-clube-de-vantagens.png",
              company: "UBANNET",
            },
            {
              text: "Ter a Partiu como parceiro proporcionou muita muitas vantagens para os nossos clientes.",
              author: "Mauro",
              job: "Diretor - MMA FIBRA",
              image:
                "https://cdn.discordapp.com/attachments/1042581944879284317/1101600049533816965/96fce3e0-3139-4954-9692-9c1d4627b16f.png",
              logo: "https://partiu-rest-s3.s3.sa-east-1.amazonaws.com/logos/logommafibra.png",
              company: "MMA FIBRA",
            },
            {
              text: "A Partiu é uma grande parceira. Esta relação de sucessos foi construída pela qualidade das suas soluções tecnológicas e principalmente, pelo foco e empatia de toda a equipe.",
              author: "Jardel",
              job: "Diretor Presidente - Assemed",
              image:
                "https://partiu-static.s3.amazonaws.com/faces/jardelassemed.jpeg",
              logo: "https://partiu-static.s3.amazonaws.com/Logos/assemed.png",
            },
          ]}
        />

        <Footer />
        <FixedButtons
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
        />
        {isMobile ? (
          <MobileDrawer openReceiveCallModal={receiveCallDisclosure.onOpen} />
        ) : (
          <></>
        )}
      </Box>
    </div>
  );
}
