import { Box, Text } from "@chakra-ui/layout";

export const SectionText = ({ children, ...style }) => {
  return (
    <Box
      w={{ base: "100%", lg: "500px" }}
      mb="20px"
      textAlign={{ base: "center", lg: "justify" }}
      display="flex"
      flexWrap="wrap"
      {...style}
    >
      <Text color="blackAlpha.700" fontSize="13pt" fontFamily="Chivo">
        {children}
      </Text>
    </Box>
  );
};
