import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

export const Tag = ({ children, width = "40px", height = "40px" }) => {
  const primaryColor = "#D82B60";
  return (
    <Flex
      background={primaryColor}
      width={width}
      height={height}
      borderRadius="200px"
      justify="center"
      align="center"
      fontWeight="600"
      fontFamily="Montserrat"
    >
      {children}
    </Flex>
  );
};
export const TextTag = ({ text }) => (
  <Tag width="fit-content">
    <Flex padding="0px 5px" align="flex-end">
      <Text fontSize="14px" color="white">
        {text}
      </Text>
    </Flex>
  </Tag>
);
export const PercentageTag = ({ percentage }) => {
  const primaryColor = "#D82B60";

  return (
    <Tag>
      <Flex align="flex-end">
        <Text color="white" fontSize="23px">
          {percentage}
        </Text>
        <Text
          position="absolute"
          background={primaryColor}
          color="white"
          borderRadius="100px"
          padding="1px"
          right="4px"
          bottom="7px"
          fontSize="7px"
          fontWeight="bolder"
        >
          %
        </Text>
      </Flex>
    </Tag>
  );
};
