import React from "react";
import { Box, List } from "@chakra-ui/react";

export const FeaturesList = ({ children }) => {
  return (
    <Box alignSelf={{ base: "center", lg: "flex-start" }}>
      <List>{children}</List>
    </Box>
  );
};
