import {
  Heading,
  HStack,
  Text,
  VStack,
  Box,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { MainButton } from "../atoms/MainButtons";

import ReactPlayer from "react-player";
import { useCheckMobile } from "../../utils/CheckMobile";

export const MainBox = ({ openReceiveCallModal, bookCalendlyMeet }) => {
  const isMobile = useCheckMobile();

  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      bgGradient="linear(to-r, #D82B60, #FFA800)"
      alignItems="center"
      pb={{ base: "1vh", lg: "70px" }}
      paddingTop="50px"
      w="100%"
    >
      <VStack
        paddingTop={{ base: "30px", lg: "50px" }}
        paddingBottom={{ base: "70px", lg: "30px" }}
        textAlign="left"
        alignItems={{ base: "center", lg: "flex-start" }}
        paddingLeft={{ base: "0", lg: "10%" }}
        align="center"
      >
        <Box
          mb="20px"
          w={{ base: "auto", lg: "350px" }}
          p={{ base: "0vh 2vh 0vh 2vh", lg: "0vh" }}
          textAlign={{ base: "center", lg: "left" }}
        >
          <Heading
            color="white"
            fontSize={{ base: "30px", lg: "42px" }}
            fontFamily="Chivo"
          >
            Faça a conta dos seus clientes sair de graça!
          </Heading>
        </Box>
        <Box
          mb="100px"
          w={{ base: "auto", lg: "400px" }}
          p={{ base: "0vh 2vh 1vh 2vh", lg: "0vh" }}
          alignSelf="center"
          textAlign={{ base: "center", lg: "left" }}
        >
          <Text textAlign="justify" color="white" fontSize="18px">
            Agregue valor ao seu serviço e mostre aos seus clientes que a conta
            da internet deles pode sair de graça. Saia na frente da
            concorrência! Clique abaixo e receba uma ligação.
          </Text>
        </Box>
        {isMobile ? (
          <ReactPlayer
            height="300px"
            width="95%"
            url="https://youtu.be/0X5U1q4fgoo"
          />
        ) : (
          <></>
        )}
        <HStack alignItems="center" pt="20px" spacing="4">
          <Box>
            <MainButton
              text="Quero o Clube de Vantagens"
              isWhite={true}
              onClick={openReceiveCallModal}
              width="210px"
            />
          </Box>
        </HStack>
      </VStack>
      <Spacer />
      <Box pr={{ base: "0%", lg: "10%" }} pt={{ base: "5vh", lg: "0vh" }}>
        {isMobile ? (
          <></>
        ) : (
          <ReactPlayer
            height="300px"
            width="550px"
            url="https://youtu.be/0X5U1q4fgoo"
          />
        )}
      </Box>
    </Flex>
  );
};
