import { Avatar, Text, VStack, HStack, Icon, Link } from "@chakra-ui/react";
import { FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export function LinkedinBall({ avatarSrc, name, job, linkedinUrl, email }) {
  return (
    <VStack spacing={0}>
      <Avatar size="2xl" src={avatarSrc} />
      <Text fontSize="18px" pt="20px">
        {name}
      </Text>
      <Text fontSize="14px" pt="0px" pb="7px" color="rgba(0,0,0,0.5)">
        {job}
      </Text>
      <HStack color="rgba(0,0,0,0.5)">
        <Link target="_blank" href={linkedinUrl}>
          <FaLinkedin />
        </Link>
        <Link target="_blank" href={"mailto:" + email}>
          <MdEmail />
        </Link>
      </HStack>
    </VStack>
  );
}
