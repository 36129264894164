import React from "react";

import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
  Stack,
  Image,
} from "@chakra-ui/react";
import { Divisor } from "../atoms/Divider";
import { useCheckMobile } from "../../utils/CheckMobile";

const BigCompanies = () => {
  return (
    <Box alignText={{ base: "center", lg: "left" }} pb="20px">
      <Heading fontSize="30px">
        Faça como grandes empresas e tenha seu clube de vantagens!
      </Heading>
    </Box>
  );
};

const ThreeMobile = () => {
  return (
    <Stack
      direction="column"
      alignContent="center"
      justify="center"
      textAlign="center"
      justifyContent="center"
      spacing={3}
    >
      <Box>
        <Heading paddingTop="20px" textAlign="center">
          Faça como grandes empresas e tenha um clube de vantagens relevante!
        </Heading>
      </Box>
      <Box mb="2vh" pb="8vh" pt="4vh">
        <Heading
          fontSize="24px"
          fontWeight="semibold"
          textAlign="center"
          color="gray.600"
        >
          Contrate hoje e tenha um clube pronto e integrado ao seu site ou app
          em
        </Heading>
        <Box>
          <Box mt="-2vh">
            <Heading fontSize="200px" color="red" textAlign="center">
              3
            </Heading>
          </Box>
          <Box>
            <Heading fontSize="90px" textAlign="center" mt="-70px">
              dias
            </Heading>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export const Promessa = () => {
  const isMobile = useCheckMobile();

  const ThreeDays = () => {
    return (
      <VStack
        flex="1"
        mt={{ base: "2vh", lg: "7vh" }}
        direction="column"
        alignContent="center"
        justifyItems={{ base: "center", lg: "flex-start" }}
        spacing={0}
        textAlign={{ base: "center", lg: "left" }}
      >
        <Box mb="0px" justifyContent="center">
          <Box
            w="250px"
            h="90px"
            mb="10px"
            justifyContent={{ base: "center", lg: "left" }}
          >
            <Heading fontSize="25px" textAlign="left">
              Faça como grandes empresas e tenha um clube de vantagens
              relevante!
            </Heading>
            <Heading
              fontSize={{ base: "20px", lg: "22px" }}
              lineHeight="27px"
              orientation="vertical"
              color="gray.500"
              mt="10px"
            >
              Contrate hoje e tenha um clube pronto e integrado ao seu site ou
              app em
            </Heading>
          </Box>
          <Box mt={{ base: "10px", lg: "110px" }}>
            <Heading
              fontSize={{ base: "200px", lg: "200px" }}
              color="#ff2138"
              fontWeight="bolder"
              fontStyle="normal"
              justifySelf="center"
            >
              3
            </Heading>
            <Box mt={{ base: "-50px", lg: "-50px" }} mb="10px">
              <Heading fontSize="80px">dias</Heading>
            </Box>
          </Box>
        </Box>
      </VStack>
    );
  };

  function Imagem() {
    return (
      <Box
        pl={{ base: "0px", lg: "50px" }}
        minH="200px"
        position="relative"
        flex="2"
      >
        <Image
          src="https://partiu-static.s3.amazonaws.com/flamengoPc.png"
          alt="Seu clube personalizado"
          w="auto"
          h="auto"
        />
      </Box>
    );
  }

  return (
    <Flex
      w={{ base: "80%", lg: "90%" }}
      direction={{ base: "column-reverse", lg: "row" }}
      justify={{ base: "center", lg: "space-between" }}
      mb={{ base: "-10vh", lg: "0" }}
      transform="translateY(-20px)"
      alignSelf="center"
      m="auto"
    >
      {isMobile ? <ThreeMobile /> : <ThreeDays />}
      <Imagem />
    </Flex>
  );
};
