import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Box,
} from "@chakra-ui/react";
import { ReceiveCallForm } from "./ReceiveCallForm";

export const LeadForm = () => {
  return (
    <Stack
      direction="column"
      h="300px"
      p="0px 30px 30px 0px"
      align="left"
      justify={{ base: "center", lg: "left" }}
      bgColor="transparent"
      position="absolute"
      zIndex="2"
      borderRadius="30"
      textShadow="0"
    >
      <Heading
        fontFamily="Chivo"
        fontWeight="bold"
        color="white"
        fontSize="29px"
        textAlign="left"
        pb="15px"
      >
        Venha ser Partiu!
      </Heading>
      <ReceiveCallForm
        doneStyle={{ color: "white", fontSize: "20px", maxWidth: "400px" }}
      />
    </Stack>
  );
};
