import {
  Flex,
  HStack,
  Stack,
  List,
  propNames,
  Box,
  Image,
  Heading,
  VStack,
  Text,
  Icon,
  Button,
  Input,
  FormControl,
} from "@chakra-ui/react";
import { FeaturesList } from "../atoms/RenderLIst";
import { ListItens } from "../atoms/ListItems";
import { Divisor } from "../atoms/Divider";
import { MainButton } from "../atoms/MainButtons";
import { useCheckMobile } from "../../utils/CheckMobile";
import Fade from "react-reveal/Fade";
import { SectionTitle } from "../atoms/SectionTitle";
import { SectionText } from "../atoms/SectionText";

export const ImageSection = (props) => {
  const hasList = props.hasList;
  const isMobile = useCheckMobile();
  const isReversed = props.isReversed;
  const whichList = props.whichList;
  const hasDivisor = props.hasDivisor;
  const iconSrc = props.iconSrc;
  const hideGreyBox = props.hideGreyBox;
  const hideButtons = props.hideButtons;

  const openReceiveCallModal = props.openReceiveCallModal;
  const bookCalendlyMeet = props.bookCalendlyMeet;
  const fadeOrigin = isReversed ? "right" : "left";
  const hasForm = props.hasForm;

  const ReusableButtons = () => {
    return (
      <>
        <HStack
          direction="row"
          pt="40px"
          spacing={2}
          align="center"
          justify={{ base: "center", lg: "flex-start" }}
        >
          <MainButton
            isWhite={false}
            text="Receba uma ligação"
            onClick={openReceiveCallModal}
          />
          <Text fontWeight="bold" fontSize="20px">
            ou
          </Text>
          <MainButton
            isWhite={false}
            text="Agende uma reunião"
            onClick={bookCalendlyMeet}
          />
        </HStack>
      </>
    );
  };

  const BePartnerForm = () => {
    return (
      <VStack align="flex-start" spacing={4} pt="30px">
        <HStack align="center" justify="center">
          <FormControl id="name">
            <Input
              color="black"
              bgColor="white"
              borderRadius="90"
              borderColor="#E13252"
              placeholder="nome"
              w="190px"
            />
          </FormControl>
          <FormControl id="email">
            <Input
              color="black"
              bgColor="white"
              borderRadius="90"
              borderColor="#E13252"
              placeholder="e-mail"
              w="190px"
            />
          </FormControl>
          <FormControl id="number">
            <Input
              color="black"
              bgColor="white"
              borderRadius="90"
              borderColor="#E13252"
              placeholder="telefone"
              w="190px"
            />
          </FormControl>
        </HStack>
        <Button
          color="white"
          bgColor="#E13252"
          borderRadius="90"
          textAlign="center"
        >
          cadastrar agora
        </Button>
      </VStack>
    );
  };

  const SmallIcon = (props) => {
    return (
      <>
        <Flex
          w="100px"
          h="70px"
          bgGradient="linear(to-b, #DF2E54, #F96D35)"
          bgColor="transparent"
          borderRadius="90"
          position="relative"
          zIndex="0"
          align="center"
          justify="center"
        >
          <Image src={iconSrc} w="auto" h="auto" alt="Ícone do segmento" />
        </Flex>
      </>
    );
  };

  return (
    <>
      <Flex
        id={props.compId}
        w={{ base: "90%", lg: "85%" }}
        m="auto"
        bgColor="white"
        position="relative"
        zIndex="10"
        direction={{ base: "column", lg: isReversed ? "row-reverse" : "row" }}
        alignItems="center"
        justify={{ base: "space-evenly", lg: "space-between" }}
        mb={{ base: "5vh", lg: "50px" }}
      >
        <Fade left={fadeOrigin === "left"} right={fadeOrigin === "right"}>
          <Box position="relative">
            {!hideGreyBox ? (
              <Box
                h={{ base: "0px", lg: "200px" }}
                w={{ base: "0", lg: "120%" }}
                position="absolute"
                left={isReversed ? null : "-20%"}
                right={isReversed ? "-20%" : null}
                top="25%"
                borderRadius={
                  !isReversed ? "0px 90px 90px 0px" : "90px 0px 0px 90px"
                }
                backgroundColor="gray.100"
                zIndex="0"
              />
            ) : (
              <></>
            )}
            <Box
              paddingRight={{
                base: "0",
                lg: props.isReversed == true ? "0%" : "7%",
              }}
              paddingLeft={{
                base: "0",
                lg: props.isReversed == true ? "7%" : "0%",
              }}
            >
              <Image
                src={props.src}
                maxW={{ base: "auto", lg: "500px" }}
                h="auto"
                alt={props.alt}
                position="relative"
                zIndex="1"
              />
            </Box>
          </Box>
        </Fade>
        <Flex
          justify={{ base: "center", lg: "flex-start" }}
          direction="column"
          pl={isReversed ? "5%" : "5%"}
          pr={isReversed ? "5%" : "5%"}
        >
          <Box alignSelf={{ base: "center", lg: "flex-start" }} pt="20px">
            {hasDivisor ? <Divisor pb="10px" /> : <SmallIcon pb="20px" />}
          </Box>
          <SectionTitle>{props.title}</SectionTitle>
          <SectionText>{props.text}</SectionText>
          <FeaturesList fontFamily="Chivo">
            {hasList ? (
              whichList.map((text, index) => {
                return (
                  <ListItens
                    textAlign={{ base: "left", lg: "left" }}
                    text={text.text}
                    key={index}
                  />
                );
              })
            ) : (
              <></>
            )}
          </FeaturesList>
          {hideButtons ? (
            <></>
          ) : isMobile ? (
            <></>
          ) : hasForm ? (
            <BePartnerForm />
          ) : (
            <ReusableButtons />
          )}
          {props.children}
        </Flex>
      </Flex>
    </>
  );
};
