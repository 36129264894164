import React from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";

export const ForButtons = (props) => {
  return (
    <>
      <a href={props.url}>
        <Flex
          h="71px"
          color="white"
          bgColor="#FF782D"
          fontSize="18px"
          fontFamily="Chivo"
          fontWeight="bold"
          w={{ base: "250px", lg: "auto" }}
          direction="row"
          transition="0.2s"
          p="10px"
          alignItems="center"
          borderRadius="lg"
          as="button"
          _hover={{
            boxShadow: "0 10px 10px rgb(0 0 0 / 20%)",
            transform: "translateY(-5px)",
          }}
        >
          <Image
            src={props.src}
            w="auto"
            h="auto"
            pr="10px"
            alt="Imagem demonstrativa de cada segmento"
          />
          <Text>{props.text}</Text>
        </Flex>
      </a>
    </>
  );
};
