import { Divider } from "@chakra-ui/react";
import React from "react";

export const Divisor = () => {
  return (
    <>
      <Divider
        orientation="horizontal"
        h="8px"
        w="100px"
        borderRadius="60"
        bgColor="#fc2027
        "
      />
    </>
  );
};
