import { VStack } from "@chakra-ui/layout";
import React from "react";

export const Card: React.FC = ({ children, ...props }) => (
  <VStack
    padding="20px"
    alignItems="flex-start"
    borderRadius="5px"
    backgroundColor="#FAFAFA"
    width="100%"
    filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
    {...props}
  >
    {children}
  </VStack>
);
