import ReactInputMask from "react-input-mask";

export const MaskedInput = (props) => {
  return (
    <ReactInputMask
      {...props}
      style={{
        border: "1px solid",
        borderColor: "inherit",
        padding: "8px 16px",
        width: "100%",
        borderRadius: "6px",
      }}
    />
  );
};
