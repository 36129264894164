import React, { useEffect, useState } from "react";

import { ImageSection } from "../../components/organisms/ImageSection";
import { Footer } from "../../components/organisms/Footer";
import { InYourHands } from "../../components/organisms/InYourHands";
import { Morethan } from "../../components/organisms/Morethan";
import { ForYourCompany } from "../../components/organisms/ForYourCompany";
import { TestimonyCarousel } from "../../components/organisms/TestimonyCarousel";
import { HomeHeader } from "../../components/organisms/HomeHeader";
import { HomeMainBox } from "../../components/organisms/HomeMainbox";
import { DiscountExamples } from "../../components/organisms/DiscountExamples";
import { BenefitsCarousel } from "../../components/organisms/MyIdea";
import { FixedButtons } from "../../components/molecules/FixedButtons";
import { useCheckMobile } from "../../utils/CheckMobile";
import { Box, useDisclosure, VStack } from "@chakra-ui/react";
import { DoubleButtons } from "../../components/atoms/DoubleButtons";
import { ReceiveCall } from "../../components/molecules/ReceiveCall";
import { MobileDrawer } from "../../components/organisms/MobileDrawer";
import { openPopupWidget } from "react-calendly";
import { VerticaisCarousel } from "../../components/organisms/VerticaisCarousel";
import { CircularCarousel } from "../../components/organisms/CircularCarousel";
import { PromoVideo } from "../../components/organisms/PromoVideo";
import { LoadingScreen } from "../../components/molecules/LoadingScreen";

export default function Home() {
  const [isLoading, setIsLoading]: [boolean, Function] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoading(false), 1000);

    return () => clearTimeout(timeout);
  }, []);

  const receiveCallDisclosure = useDisclosure();
  const isMobile = useCheckMobile();
  const listText = [
    {
      text: "Campanhas semanais personalizadas",
    },
    {
      text: "Divulgação de novas parcerias",
    },
    {
      text: "Incentivo ao uso da plataforma",
    },
    {
      text: "Comunicação através de push notifications",
    },
    {
      text: "E muito mais...",
    },
  ];

  const preFill = {
    name: "Jon Snow",
    firstName: "Jon",
    lastName: "Snow",
    email: "test@test.com",
  };

  const url = "https://calendly.com/prevendas-bju/reuniao-partiu";

  const pageSettings = {
    backgroundColor: "cbe5e2",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
  };

  const onClick = () => openPopupWidget({ url, pageSettings });

  return (
    <LoadingScreen isLoading={isLoading}>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <HomeMainBox
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
        />
        <Box
          backgroundColor="white"
          position="relative"
          width="102%"
          height="200px"
          marginBottom="-170px"
          transform={{
            base: "translateY(-20%)",
            lg: "rotate(3deg) translateY(-20%) translateX(-2%);",
          }}
          zIndex="0px"
        >
          <Box
            width={{ base: "50px", lg: "300px" }}
            height={{ base: "100px", lg: "400px" }}
            backgroundColor="transparent"
            boxShadow={{ base: "0px 50px white", lg: "0px 200px white" }}
            borderBottomLeftRadius="200px"
            position="absolute"
            top={{ base: "-100px", lg: "-400px" }}
          />
          <Box
            width={{ base: "50px", lg: "400px" }}
            height={{ base: "100px", lg: "500px" }}
            backgroundColor="transparent"
            boxShadow={{ base: "0px 50px white", lg: "0px 300px white" }}
            borderBottomRightRadius="200px"
            position="absolute"
            right="0px"
            top={{ base: "-100px", lg: "-500px" }}
          />
        </Box>
        <ReceiveCall {...receiveCallDisclosure} />
        <InYourHands />
        <Morethan />
        <ImageSection
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
          isReversed={false}
          hasList={true}
          whichList={listText}
          hasDivisor={true}
          hasForm={false}
          src={
            "https://partiu-static.s3.amazonaws.com/images/ImagemVerticais.png"
          }
          title="Seus clientes conectados com  grandes marcas!"
          alt="Exemplos de descontos que a Partiu oferece."
          text="Através do clube de vantagens com a identidade visual da sua empresa, os seus clientes estarão conectados a milhares de estabelecimentos físicos e ecommerces com descontos exclusivos."
        />
        <DiscountExamples />
        <CircularCarousel />

        <ForYourCompany />

        <ImageSection
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
          compId="forClient"
          isReversed={false}
          hasDivisor={false}
          hasList={false}
          iconSrc="https://partiu-static.s3.amazonaws.com/icons/clienticon.png"
          src={
            "https://partiu-static.s3.amazonaws.com/images/DesenhoComprar.png"
          }
          title="Para seu cliente"
          text="Não importa se sua empresa é pequena ou grande, nossa solução é completa, modular e adaptável para todas as necessidades.
        Quem não quer economizar ao comprar produtos e serviços oferecidos pelos principais e-commerces do Brasil e nas principais lojas e restaurantes da sua região?
        Ao se cadastrar na nossa plataforma, eles terão acesso a descontos relevantes. A utilização dessas vantagens gera uma economia financeira real e considerável."
          alt="Imagem genérica"
        />
        <ImageSection
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
          compId="forAssociation"
          hasDivisor={false}
          id={44}
          isReversed={true}
          fadeOrigin="right"
          hasList={false}
          iconSrc="https://partiu-static.s3.amazonaws.com/icons/associationicon.png"
          src={"https://partiu-static.s3.amazonaws.com/images/DesenhoChave.png"}
          alt="Imagem generica"
          title="Para sua associação"
          text="E se você pudesse potencializar os benefícios já proporcionados pela sua associação ou sindicato e oferecer centenas de novas vantagens exclusivas?
        Conte com a Partiu! e ofereça, em apenas 3 dias úteis, um clube de vantagens completo, com descontos reais em diversos segmentos."
        />
        <ImageSection
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
          compId="forEmployee"
          hasDivisor={false}
          isReversed={false}
          hasList={false}
          iconSrc="https://partiu-static.s3.amazonaws.com/icons/employeeicon.png"
          src={
            "https://partiu-static.s3.amazonaws.com/images/DesenhoBuracos.png"
          }
          alt="imagem generica"
          title="Para seu funcionário"
          text="Sabemos que uma equipe motivada trabalha muito mais feliz!
        Então aumente a produtividade e a motivação de seus colaboradores, disponibilizando um clube de vantagens completo com diversos benefícios."
        />
        <VerticaisCarousel />
        <ImageSection
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
          id="dashboard"
          isReversed={true}
          hasList={true}
          hasDivisor={true}
          whichList={listText}
          src={"https://partiu-static.s3.amazonaws.com/images/computador1.png"}
          text="Nós disponibilizamos um painel de controle completo, que permite ter uma visão geral dos hábitos de interesse e consumo de seus clientes."
          title="Entenda melhor o comportamento do seu cliente com o dashboard."
        />
        <PromoVideo />

        <TestimonyCarousel
          items={[
            {
              text: "A Partiu está conosco há 4 anos e sempre tivemos um atendimento muito presente, buscando agregar valor para nossos colaboradores e clientes tanto na administradora quanto na imobiliária.",
              author: "Marcelo Borges",
              job: "Diretor Administrativo ML Administradora",
              image:
                "https://partiu-static.s3.amazonaws.com/faces/marceloml.png",
              logo: "https://partiu-static.s3.amazonaws.com/Logos/ml-administradora.png",
              company: "ML Administradora",
            },
            {
              text: "Iniciamos a parceria em 2017. Ao longo dos anos percebemos sempre a constante evolução no produto, trazendo novas parcerias relevantes para síndicos e condôminos.",
              author: "Dennys Teles",
              job: "Diretor administrativo Atlântida",
              image:
                "https://partiu-static.s3.amazonaws.com/faces/dennysatlantida.png",
              logo: "https://partiu-static.s3.amazonaws.com/Logos/atlantida.png",
              company: "Atlantida Administradora",
            },
            {
              text: "A experiência com a Partiu Vantagens tem sido transformadora em nosso empreendimento, tando para o nosso negócio, quanto para as pessoas.",
              author: "Danilo",
              job: "Diretor - UBANNET",
              image:
                "https://cdn.discordapp.com/attachments/1042581944879284317/1101599735942492182/366c32d6-e102-4f98-83fc-03f175eececf.png",
              logo: "https://partiu-rest-s3.s3.sa-east-1.amazonaws.com/logos/Logo-Uba-clube-de-vantagens.png",
              company: "UBANNET",
            },
            {
              text: "Ter a Partiu como parceiro proporcionou muita muitas vantagens para os nossos clientes.",
              author: "Mauro",
              job: "Diretor - MMA FIBRA",
              image:
                "https://cdn.discordapp.com/attachments/1042581944879284317/1101600049533816965/96fce3e0-3139-4954-9692-9c1d4627b16f.png",
              logo: "https://partiu-rest-s3.s3.sa-east-1.amazonaws.com/logos/logommafibra.png",
              company: "MMA FIBRA",
            },
            {
              text: "A Partiu é uma grande parceira. Esta relação de sucessos foi construída pela qualidade das suas soluções tecnológicas e principalmente, pelo foco e empatia de toda a equipe.",
              author: "Jardel",
              job: "Diretor Presidente - Assemed",
              image:
                "https://partiu-static.s3.amazonaws.com/faces/jardelassemed.jpeg",
              logo: "https://partiu-static.s3.amazonaws.com/Logos/assemed.png",
            },
          ]}
        />

        <Footer />
        <FixedButtons
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
        />
        {isMobile ? (
          <MobileDrawer openReceiveCallModal={receiveCallDisclosure.onOpen} />
        ) : (
          <></>
        )}
      </div>
    </LoadingScreen>
  );
}
