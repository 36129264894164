import { Image } from "@chakra-ui/image";
import { Box } from "@chakra-ui/layout";
import React, { useState } from "react";

const IconBall = ({ icon, isActive = false, onClick, ...style }) => {
  const size = 33;

  return (
    <Box
      background="linear-gradient(45deg, #D82B60, #FFA800);"
      borderRadius="100px"
      padding={10 + (isActive ? 5 : 0) + "px"}
      position="absolute"
      cursor="pointer"
      transition="0.2s"
      onClick={onClick}
      maxW={size + 20 + (isActive ? 10 : 0) + "px"}
      maxH={size + 20 + (isActive ? 10 : 0) + "px"}
      _hover={{
        padding: "15px",
        maxW: size + 30 + "px",
        maxH: size + 30 + "px",
      }}
      {...style}
    >
      <Image
        transition="0px"
        width={size + "px"}
        height={size + "px"}
        src={icon}
        alt="icon"
      />
    </Box>
  );
};

export const Neymar = ({ onChange, value }) => {
  return (
    <Box>
      <Box
        position="relative"
        border="1.5px solid #555"
        borderRadius="900px"
        padding="37px"
      >
        <Box
          border="1.5px solid rgba(0,0,0,0.1)"
          borderRadius="900px"
          padding="30px"
          boxShadow="0px 0px 10px rgba(0,0,0,0.2)"
        >
          <Box
            border="1.5px solid rgba(0,0,0,0.1)"
            borderRadius="900px"
            padding="30px"
            boxShadow="0px 0px 10px rgba(0,0,0,0.2)"
          >
            <Image
              width="60px"
              alt="logo menor"
              src="https://partiu-static.s3.amazonaws.com/Logos/logoMenorPartiu.jpg"
            />
            <IconBall
              right="-30px"
              top="0px"
              bottom="0px"
              margin="auto"
              isActive={value === "management"}
              onClick={() => onChange("management")}
              icon="https://partiu-static.s3.amazonaws.com/icons/brazillogo.png"
            />
            <IconBall
              right="30px"
              bottom="-10px"
              margin="auto"
              icon="https://partiu-static.s3.amazonaws.com/icons/brazildot.png"
              isActive={value === "gps"}
              onClick={() => onChange("gps")}
            />
            <IconBall
              isActive={value === "cpu"}
              onClick={() => onChange("cpu")}
              left="30px"
              bottom="-10px"
              margin="auto"
              icon="https://partiu-static.s3.amazonaws.com/icons/cpu.png"
            />
            <IconBall
              isActive={value === "customer-service"}
              onClick={() => onChange("customer-service")}
              left="-30px"
              top="0px"
              bottom="0px"
              margin="auto"
              icon="https://partiu-static.s3.amazonaws.com/icons/customer-service.png"
            />
            <IconBall
              right="30px"
              top="-10px"
              margin="auto"
              icon="https://partiu-static.s3.amazonaws.com/icons/megaphone.png"
              isActive={value === "megaphone"}
              onClick={() => onChange("megaphone")}
            />
            <IconBall
              isActive={value === "choice"}
              onClick={() => onChange("choice")}
              left="30px"
              top="-10px"
              margin="auto"
              icon="https://partiu-static.s3.amazonaws.com/icons/choice.png"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
