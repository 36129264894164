import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReceiveCallForm } from "./ReceiveCallForm";

export const ReceiveCall = ({ isOpen, onClose }) => {
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bgColor="transparent" color="black" textAlign="center">
            Receba uma ligação agora!
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ReceiveCallForm onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
