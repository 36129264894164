import { Box, Button, Heading, HStack, Image, Link, Stack, Text, VStack } from "@chakra-ui/react";
import { FaCheck, FaDownload } from "react-icons/fa";
import { Footer } from "../../components/organisms/Footer";
import { NavBar } from "../../components/organisms/Header";
import { useCheckMobile } from "../../utils/CheckMobile";
import React from "react";

const currentEdition = {
    downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
    datetime: "Janeiro/2023"
}


interface IPlanCardProps{
    title: string;
    items: String[];
    vindiUrl: string;
}

const PlanCard: React.FC<IPlanCardProps> = ({title, items, vindiUrl}) => {
    return (
        <Box bgColor={"white"} padding="24px" border="2px" width="30%" borderRadius={"10px"} borderColor={"#ddd"}>
            <VStack>
                <Text fontSize={"24px"} textAlign={"center"} fontWeight="bold">{title}</Text>
                <VStack spacing={5}>
                    {items.map(item => (
                        <HStack>
                            <FaCheck size="10pt" color="green" />
                            <Text width="220px" fontSize="11pt" lineHeight="1.35rem">
                                {item}
                            </Text>
                        </HStack>
                    ))}
                </VStack>
                <Link href={vindiUrl} target="_blank">
                    <Button 
                        bgGradient="linear(to-t, #D82B60, #FFA800)"
                        marginY={6}
                        color="white"
                    >
                        Contratar
                    </Button>
                </Link>
            </VStack>
        </Box>
    )
}

const Revista = () => {
    const isMobile = useCheckMobile();

    return (
        <VStack
            alignItems="center"
            justifyContent="center"
            w="100%"
            spacing={0}
            bgColo={"#e2e2e2"}
        >
            <NavBar />
            <Stack
                width="100%"
            >
                <Stack
                    direction="column"
                    bgGradient="linear(to-t, #D82B60, #FFA800)"
                    height="300px"
                    paddingTop="150px !important"
                    width="100%"
                    id="top"
                >
                    <Heading
                        color="white"
                        fontFamily="Chivo, sans-serif"
                        fontWeight="bold"
                        fontSize={isMobile ? "30pt" : "40pt"}
                        textAlign="center"
                        mb="40px"
                    >
                        REVISTA PARTIU
                    </Heading>
                </Stack>
                <Stack
                    direction="column"
                    justify={"center"}
                    alignItems={"center"}
                    maxWidth={"100%"}
                >
                    <Text maxWidth={"920px"} textAlign={"center"} marginX="auto" paddingY={"100px"}>
                        Leve a experiência de leitura digital da sua marca para o próximo nível! Com nossa revista personalizada, você pode destacar conteúdos exclusivos, promover seus produtos ou serviços e reforçar a identidade da sua empresa. Transforme a comunicação com seus clientes em algo único e inovador. Solicite já a sua versão personalizada e surpreenda!
                    </Text>
                    <Image
                        src="https://partiu-rest-s3.s3.sa-east-1.amazonaws.com/revista-banner-1.png"
                    />
                    <Text maxWidth={"920px"} textAlign={"center"} marginX="auto" paddingY={"100px"}>
                        Com essas ofertas exclusivas, seus clientes poderão aproveitar promoções especiais, garantindo economia significativa em cada compra. Além de fortalecer o vínculo com sua marca, você oferece a eles uma experiência de consumo mais vantajosa e acessível. Transforme a forma como seus clientes economizam e fidelize seu público com benefícios reais!
                    </Text>
                    <Image
                        src="https://partiu-rest-s3.s3.sa-east-1.amazonaws.com/revista-banner-2.png"
                    />
                    <Text maxWidth={"920px"} textAlign={"center"} marginX="auto" paddingY={"100px"}>
                        Descubra o poder de uma revista digital totalmente personalizada, renovada mensalmente, e com mais de 2.000 parcerias exclusivas. Com três categorias à sua escolha – ouro, prata e bronze –, oferecemos conteúdos feitos sob medida para atender às necessidades da sua empresa. Além disso, nossos aplicativos personalizados garantem que sua marca esteja sempre conectada ao público. Personalize, destaque e surpreenda com nossas soluções inovadoras
                    </Text>
                    <Image
                        src="https://partiu-rest-s3.s3.sa-east-1.amazonaws.com/revista-banner-3.png"
                        width={"100%"}
                    />
                </Stack>
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={0}
                    w="100%"
                    pt={isMobile ? "200px" : "200px"}
                >
                    
                    <Link
                        href={currentEdition.downloadUrl}
                        download="current-edition.pdf"
                        target="_blank"
                        rel="noreferrer noopener"
                        _hover={{ textDecoration: "none" }}
                    >
                        <Stack
                            flexDir="row"
                            alignItems="center"
                            justifyContent="center"
                            color="white"
                            bgColor="#fc195a"
                            h="60px"
                            p="10px"
                            whiteSpace="normal"
                            text-align="center"
                            fontWeight="semibold"
                            lineHeight="20px"
                            fontSize={{ base: "15px", lg: "17px" }}
                            borderRadius="lg"
                            _hover={{
                                boxShadow: "0 10px 10px rgb(0 0 0 / 20%)",
                                transform: "translateY(-5px)",
                            }}
                        >
                            <Text
                                fontSize="14pt"
                            >
                                Baixar última edição
                            </Text>
                            <Stack pb="12px" pl="7px"><FaDownload size={20} /></Stack>
                        </Stack>
                    </Link>
                </Stack>
            </Stack>
            <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                paddingY={"48px"}
            >
                <Stack>
                    <Text maxWidth={"900px"} width="90%" paddingY={"42px"} textAlign={"center"}>
                        Encontre o plano perfeito para você e comece a economizar com descontos exclusivos e nossa revista digital cheia de ofertas! Escolha entre as opções Bronze, Prata ou Ouro.
                        <br/>
                        <br/>
                        Escolha seu plano e comece a aproveitar todos os benefícios!"
                    </Text>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-around"}
                    >
                        <PlanCard 
                            title="Bronze"
                            items={[
                                "1 acesso",
                                "+ de 2000 descontos em lojas próximas de você",
                                "Mais de 300 descontos em lojas online" 
                            ]}
                            vindiUrl={"https://partiu.app.vindi.com.br/customer/pages/dad01efd-9c6a-4345-9fad-d3e18a9d50ca/subscriptions/new"}
                        />
                        <PlanCard 
                            title="Prata"
                            items={[
                                "2 acessos",
                                "+ de 2000 descontos em lojas próximas de você",
                                "Mais de 300 descontos em lojas online" 
                            ]}
                            vindiUrl={"https://partiu.app.vindi.com.br/customer/pages/dad01efd-9c6a-4345-9fad-d3e18a9d50ca/subscriptions/new"}

                        />
                        <PlanCard 
                            title="Ouro"
                            items={[
                                "3 acessos",
                                "+ de 2000 descontos em lojas próximas de você",
                                "Mais de 300 descontos em lojas online" 
                            ]}
                            vindiUrl={"https://partiu.app.vindi.com.br/customer/pages/dad01efd-9c6a-4345-9fad-d3e18a9d50ca/subscriptions/new"}
                        />
                    </Stack>
                </Stack>

            </Stack>
            {/* <Stack
                direction="column"
                w="100%"
            >
                <Heading
                    color="#fc195a"
                    fontFamily="Chivo, sans-serif"
                    fontWeight="bold"
                    fontSize={isMobile ? "15pt" : "30pt"}
                    textAlign="center"
                    mb="40px"
                    mt={isMobile ? "0px" : "200pt"}
                >
                    OUTRAS EDIÇÕES
                </Heading>
                <Stack
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                    w="100%"
                    spacing="0"
                    flexWrap="wrap"
                    gap="10px"
                >
                    {
                        MockRevistaData.map(({datetime, downloadUrl}) => (
                            <Card
                                isMobile={isMobile}
                                datetime={datetime}
                                downloadUrl={downloadUrl}
                            />
                        ))
                    }
                </Stack>
            </Stack> */}
            <Footer />
        </VStack>
    )
}

export default Revista;