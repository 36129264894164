import { Box, Heading } from "@chakra-ui/layout";

export const SectionTitle = ({ children, ...style }) => {
  return (
    <Box
      mb="20px"
      display="flex"
      flexWrap="wrap"
      maxW={{ base: "100%", lg: "450px" }}
      height="auto"
      lineHeight="40px"
      mt="20px"
      textAlign={{ base: "center", lg: "left" }}
      {...style}
    >
      <Heading fontSize="20pt" color="#DA2257" fontFamily="Chivo">
        {children}
      </Heading>
    </Box>
  );
};
