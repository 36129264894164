import axios from "axios";

let baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  validateStatus: (status) => {
    if (status === 401) {
      setAccessToken(null);
      localStorage.removeItem("token");
      window.location.reload();
    }
    return status >= 200 && status < 400;
  },
});

export const axiosInstanceV2 = axios.create({
  baseURL: baseUrl.replace("v1", "v2"),
  validateStatus: (status) => {
    if (status === 401) {
      setAccessToken(null);
      localStorage.removeItem("token");
      window.location.reload();
    }
    return status >= 200 && status < 400;
  },
});

export const setAccessToken = (token) => {
  if (token == null) {
    axiosInstance.defaults.headers.common["Authorization"] = null;
    axiosInstanceV2.defaults.headers.common["Authorization"] = null;
  }

  axiosInstance.defaults.headers.common["Authorization"] = `TOKEN ${token}`;
  axiosInstanceV2.defaults.headers.common["Authorization"] = `TOKEN ${token}`;
};
