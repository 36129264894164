import { Box, Divider, Flex, Heading, Image, Stack } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { LogosCarousel } from "./LogosCarousel";

const Partners = [
  {
    id: 1,
    alt: "Logo da Netshoes",
    imageSrc: "https://partiu-static.s3.amazonaws.com/Logos/netshoes.png",
  },
  {
    id: 2,
    alt: "Logo da Marisa",
    imageSrc: "https://partiu-static.s3.amazonaws.com/Logos/marisa.png",
  },
  {
    id: 3,
    alt: "Logo da Ponto Frio",
    imageSrc: "https://partiu-static.s3.amazonaws.com/Logos/ponto_frio.png",
  },
  {
    id: 4,
    alt: "Logo da Reserva",
    imageSrc: "https://partiu-static.s3.amazonaws.com/Logos/reserva-logo.png",
  },
  {
    id: 5,
    alt: "Logo da Centauro",
    imageSrc: "https://partiu-static.s3.amazonaws.com/Logos/centauro-logo.png",
  },
  {
    id: 6,
    alt: "Logo do Extra",
    imageSrc:
      "https://partiu-static.s3.amazonaws.com/Logos/extra-logo-mercado.png",
  },
  {
    id: 7,
    alt: "Logo da Armadillo",
    imageSrc: "https://partiu-static.s3.amazonaws.com/Logos/ARMADILLO.png",
  },
  {
    id: 8,
    alt: "Logo da Mobly",
    imageSrc: "https://partiu-static.s3.amazonaws.com/Logos/mobly.png",
  },
  {
    id: 9,
    alt: "Logo das Casas Bahia",
    imageSrc: "https://partiu-static.s3.amazonaws.com/Logos/casas_bahia.png",
  },
  {
    id: 10,
    alt: "Logo da Dominos",
    imageSrc: "https://partiu-static.s3.amazonaws.com/Logos/dominos.png",
  },
];

const SectionTitle = () => {
  return (
    <Stack mb="10px" direction="row" justify="center">
      <Heading
        fontWeight="bold"
        fontSize="20pt"
        color="black"
        p="25px"
        display="flex"
      >
        + de{" "}
        <Heading color="orange" fontSize="20pt" ml="5px" mr="5px">
          1500{" "}
        </Heading>{" "}
        parceiros
      </Heading>
    </Stack>
  );
};

export const Morethan = () => {
  return (
    <Flex
      w="100%"
      bgGradient="linear(to-t,gray.100, white, gray.100)"
      h="auto"
      justifyContent="center"
      alignContent="center"
      mb="1%"
      borderBottom="1px solid"
      borderTop="1px solid"
      borderColor="gray.100"
      direction="column"
    >
      <SectionTitle />
      <LogosCarousel items={Partners} />
    </Flex>
  );
};
