import React from "react";
import { Flex, Image, VStack, HStack } from "@chakra-ui/react";
import { useRef } from "react";
import { useEffect } from "react";

const CarouselCard = ({ id, imageSrc, alt }) => {
  return (
    <Flex
      maxW="10px"
      minW="160px"
      maxH="40px"
      minH="40px"
      border="0px solid"
      borderRadius="md"
      p="10px"
    >
      <Image src={imageSrc} alt={alt} id={alt} objectFit="contain" />
    </Flex>
  );
};

export function LogosCarousel({ items }) {
  const carouselRef = useRef();

  useEffect(() => {
    if (!carouselRef.current) return;

    function checkVisible(elm) {
      var rect = elm.getBoundingClientRect();
      var viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }

    const interval = setInterval(() => {
      if (checkVisible(carouselRef.current))
        carouselRef.current.scrollLeft += 1;

      if (
        carouselRef.current.scrollLeft ===
        carouselRef.current.scrollWidth - carouselRef.current.offsetWidth
      ) {
        carouselRef.current.scrollLeft = 0;
      }
    }, 40);

    return () => clearInterval(interval);
  }, [carouselRef]);

  return (
    <VStack spacing={5} marginTop="0px" marginBottom="0px">
      <HStack
        paddingBottom="30px"
        spacing={10}
        width="100%"
        paddingLeft={{ base: "5%", lg: "6%" }}
        overflowX="hidden"
        ref={carouselRef}
        scrollBehavior="smooth"
      >
        {items.map((item, index) => (
          <CarouselCard {...item} key={index} />
        ))}
      </HStack>
    </VStack>
  );
}
