import { Image, Center, Heading, VStack } from "@chakra-ui/react";
import { CircularProgress } from "@chakra-ui/progress";
import React, { useEffect, useState } from "react";

export const LoadingScreen: React.FC<{ isLoading: boolean }> = ({
  isLoading,
  children,
}) => {
  const [isDisabled, setDisabled]: [boolean, Function] = useState(isLoading);

  useEffect(() => {
    if (isLoading === false) setTimeout(() => setDisabled(true), 500);
    else setDisabled(false);
  }, [isLoading]);

  return (
    <>
      <Center
        position="fixed"
        zIndex={isDisabled ? "-1" : "99999"}
        top="0px"
        left="0px"
        width="100%"
        height="100%"
        transition="1s"
        backgroundColor="#f1f1f1"
        opacity={isLoading ? 1 : 0}
      >
        <VStack spacing={5}>
          <CircularProgress color="#D91F59" size={20} isIndeterminate />
          <Image
            paddingTop="30px"
            src="https://partiu-static.s3.amazonaws.com/Logos/logo.png"
            alt="Logo da Partiu"
            width="100px"
          />
          <Heading color="rgba(0,0,0,0.6)" fontSize="25px">
            Carregando...
          </Heading>
        </VStack>
      </Center>
      {children}
    </>
  );
};
