import React from "react";
import { HStack, Box, Button, Text, Stack } from "@chakra-ui/react";
import { WhatsappButton } from "./WhatsAppButton";
import { ChatbotButton } from "./ChatbotButton";

import { useCheckMobile } from "../../utils/CheckMobile";

const ResizedMainButtons = (props) => {
  return (
    <>
      <Button
        w="25vh"
        h="6vh"
        minH="50px"
        minW="200px"
        bgColor="#4B42C5"
        whiteSpace="normal"
        display="flex"
        color="white"
        fontWeight="extrabold"
        fontSize="15px"
        onClick={props.onClick}
      >
        {props.text}
      </Button>
    </>
  );
};

export const FixedButtons = ({ openReceiveCallModal, bookCalendlyMeet }) => {
  const isMobile = useCheckMobile();

  return (
    <HStack position="fixed" right="50px" bottom="50px" zIndex="100">
      {!isMobile ? (
        <Stack direction={{ base: "column", lg: "row" }} align="center">
          <ResizedMainButtons
            onClick={bookCalendlyMeet}
            text="Agende uma reunião"
          />
          <Text fontWeight="extrabold" color="gray.600">
            OU
          </Text>
          <ResizedMainButtons
            onClick={openReceiveCallModal}
            text="Receba uma ligação agora"
          />
        </Stack>
      ) : (
        <></>
      )}
      <Box mr="30px">
        <WhatsappButton />
      </Box>
    </HStack>
  );
};
