import { Footer } from "../../components/organisms/Footer";
import { NavBar } from "../../components/organisms/Header";
import {
  BePartnerMainBox,
  MainBePartner,
} from "../../components/organisms/BePartnerMainBox";
import { VerticaisCarousel } from "../../components/organisms/VerticaisCarousel";
import { WorldOfDiscounts } from "../../components/organisms/WorldOfDiscounts";
import { ImageSection } from "../../components/organisms/ImageSection";
import { Morethan } from "../../components/organisms/Morethan";
import { DashboardSection } from "../../components/organisms/Dashboard";
import { TestimonyCarousel } from "../../components/organisms/TestimonyCarousel";
import { Button } from "@chakra-ui/button";
import { Link } from "@chakra-ui/layout";

const beList = [
  {
    index: 1,
    text: "Lorem ipsum sit dolor amet",
  },
  {
    index: 2,
    text: "Loram ipsum sit dolor amet 2",
  },
  {
    index: 3,
    text: "Lorem ipsum sit amet dolor 4",
  },
];

const nationalEcommerceList = [
  {
    index: 1,
    text: "Lembre-se que é importante conceder um desconto real e o mais atrativo possível.",
  },
  {
    index: 2,
    text: "Preencha o formulário online de cadastro. Em breve o nosso consultor entrará em contato com você para que a parceria seja confirmada!",
  },
];

export default function BePartner() {
  return (
    <>
      <NavBar />
      <MainBePartner />
      <WorldOfDiscounts />
      <Morethan />
      <ImageSection
        iconSrc={
          "https://partiu-static.s3.amazonaws.com/icons/shopping-cart.png"
        }
        hideButtons={true}
        src={"https://partiu-static.s3.amazonaws.com/images/ecom.png"}
        title="E-commerce nacional"
        alt="Exemplos de descontos que a Partiu oferece."
        isReversed={true}
        hasList={true}
        whichList={nationalEcommerceList}
        text="Você tem uma loja virtual que atende todo o país? Ótimo, então faça parte do Clube!"
      >
        <Link marginTop="30px" href="#top">
          <Button>Cadastre-se Já!</Button>
        </Link>
      </ImageSection>

      <ImageSection
        hideButtons={true}
        iconSrc={"https://partiu-static.s3.amazonaws.com/icons/ecommerce.png"}
        src={"https://partiu-static.s3.amazonaws.com/images/elocal.png"}
        title="E-commerce local"
        alt="Exemplos de descontos que a Partiu oferece."
        isReversed={false}
        hasList={true}
        whichList={nationalEcommerceList}
        text="Que tal aumentar as vendas da sua loja virtual? Se você tem um ecommerce que faz entrega na região da sua cidade, cadastre-se agora!"
      >
        <Link marginTop="30px" href="#top">
          <Button>Cadastre-se Já!</Button>
        </Link>
      </ImageSection>

      <ImageSection
        hideButtons={true}
        iconSrc={"https://partiu-static.s3.amazonaws.com/icons/shop.png"}
        src={"https://partiu-static.s3.amazonaws.com/images/nacional.png"}
        title="Loja Fisica"
        alt="Exemplos de descontos que a Partiu oferece."
        isReversed={true}
        hasList={true}
        whichList={nationalEcommerceList}
        text="Gostaria de aumentar o fluxo de clientes do seu estabelecimento físico? Seja nosso parceiro!"
      >
        <Link marginTop="30px" href="#top">
          <Button>Cadastre-se Já!</Button>
        </Link>
      </ImageSection>
      <ImageSection
        hideButtons={true}
        isReversed={false}
        hasDivisor={true}
        src={"https://partiu-static.s3.amazonaws.com/images/Sistema_pdv.png"}
        text="Acesse o seu canal exclusivo através de login e senha. Você terá acesso a um painel simples e intuitivo para validação dos descontos, transações, ativações e acompanhamento."
        title="Uma plataforma completa para você administrar e visualizar as vendas realizadas."
      >
        <Link marginTop="30px" href="#top">
          <Button>Cadastre-se Já!</Button>
        </Link>
      </ImageSection>
      <VerticaisCarousel />
      <TestimonyCarousel
        items={[
          {
            text: "A Partiu está conosco há 4 anos e sempre tivemos um atendimento muito presente, buscando agregar valor para nossos colaboradores e clientes tanto na administradora quanto na imobiliária.",
            author: "Marcelo Borges",
            job: "Diretor Administrativo ML Administradora",
            image: "https://partiu-static.s3.amazonaws.com/faces/marceloml.png",
            logo: "https://partiu-static.s3.amazonaws.com/Logos/ml-administradora.png",
            company: "ML Administradora",
          },
          {
            text: "Iniciamos a parceria em 2017. Ao longo dos anos percebemos sempre a constante evolução no produto, trazendo novas parcerias relevantes para síndicos e condôminos.",
            author: "Dennys Teles",
            job: "Diretor administrativo Atlântida",
            image:
              "https://partiu-static.s3.amazonaws.com/faces/dennysatlantida.png",
            logo: "https://partiu-static.s3.amazonaws.com/Logos/atlantida.png",
            company: "Atlantida Administradora",
          },
          {
            text: "A experiência com a Partiu Vantagens tem sido transformadora em nosso empreendimento, tando para o nosso negócio, quanto para as pessoas.",
            author: "Danilo",
            job: "Diretor - UBANNET",
            image:
              "https://cdn.discordapp.com/attachments/1042581944879284317/1101599735942492182/366c32d6-e102-4f98-83fc-03f175eececf.png",
            logo: "https://partiu-rest-s3.s3.sa-east-1.amazonaws.com/logos/Logo-Uba-clube-de-vantagens.png",
            company: "UBANNET",
          },
          {
            text: "Ter a Partiu como parceiro proporcionou muita muitas vantagens para os nossos clientes.",
            author: "Mauro",
            job: "Diretor - MMA FIBRA",
            image:
              "https://cdn.discordapp.com/attachments/1042581944879284317/1101600049533816965/96fce3e0-3139-4954-9692-9c1d4627b16f.png",
            logo: "https://partiu-rest-s3.s3.sa-east-1.amazonaws.com/logos/logommafibra.png",
            company: "MMA FIBRA",
          },
          {
            text: "A Partiu é uma grande parceira. Esta relação de sucessos foi construída pela qualidade das suas soluções tecnológicas e principalmente, pelo foco e empatia de toda a equipe.",
            author: "Jardel",
            job: "Diretor Presidente - Assemed",
            image:
              "https://partiu-static.s3.amazonaws.com/faces/jardelassemed.jpeg",
            logo: "https://partiu-static.s3.amazonaws.com/Logos/assemed.png",
          },
        ]}
      />
      <Footer />
    </>
  );
}
